<template>
  <div class="id">
    <app-page-notice />
    <div class="navigation">
      <a
        href="#reset"
        @click.prevent="returnToWizard()"
      >
        Return to full search
      </a>
    </div>
    <div class="search-results-id search-results-section">
      <div
        class="search-results-section-title"
        @click="toggleDrawer('id')"
      >
        <font-awesome-icon
          :class="{ rotateIconDown: drawers.id, drawerIcon: true }"
          icon="caret-right"
        />
        Alert Id
      </div>
      <div
        v-if="drawers.id"
        class="search-results-section-content"
      >
        <input
          v-model="id"
          value="id"
          class="search-results-id-input"
        >
      </div>
    </div>
    <app-alerts
      v-if="showAlerts"
      :loader="alertsLoader"
      :lookup-by-id="Boolean(id)"
      :zones="zones"
      class="search-results"
    />
  </div>
</template>

<script>
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import AppAlerts from '../Alerts.vue'
import AppPageNotice from '../PageNotice.vue'

library.add(faCaretRight)

export default {
  components: {
    AppAlerts,
    AppPageNotice,
    FontAwesomeIcon
  },
  props: {
    query: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      drawers: {
        id: true
      },
      id: null
    }
  },
  computed: {
    alertsLoader () {
      return this.$api.alerts.get(this.id)
    },
    showAlerts () {
      return this.id
    },
    zones () {
      return this.$store.getters.zones.reduce((map, zone) => {
        map[zone.id] = zone
        return map
      }, {})
    }
  },
  watch: {
    async alertsLoader () {
      if (this.id) {
        this.$store.commit('alertsLoader', this.alertsLoader)
        await this.alertsLoader.load()
      }
    },
    $route: {
      immediate: true,
      handler () {
        if (this.$route.params.hasOwnProperty('id')) {
          this.id = this.$route.params.id
        }
      }
    }
  },
  methods: {
    returnToWizard () {
      this.$router.push({
        path: '/'
      })
    },
    toggleDrawer (id) {
      this.drawers[id] = !this.drawers[id]
    }
  }
}
</script>

<style scoped>
.navigation {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}

.navigation > a {
  display: block;
}

.search-results-section {
  border-left: 5px solid #999;
  background: #efefef;
  margin-bottom: 10px;
}

.search-results-section-title {
  padding: 10px 10px;
  font-size: 106%;
  font-weight: bold;
  cursor: pointer;
}

.search-results-section-content {
  padding: 4px 10px 10px 20px;
}

.drawerIcon {
  transition: 0.5s;
  font-size: 20px;
  vertical-align: text-bottom;
  margin-right: 3px;
}

.rotateIconDown {
  transform: rotate(90deg);
}

.search-results-id-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}
</style>
