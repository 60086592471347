<template>
  <article class="summary shadow">
    <div class="content">
      <div class="topbar">
        <div class="topbar-quickview">
          <span :class="['urgency', alert.urgency, 'topbar-quickview-value']">{{ alert.urgency }}</span>
          <span class="topbar-quickview-divider">/</span>
          <span :class="['status', alert.status, 'topbar-quickview-value']">{{ alert.status }}</span>
        </div>
        <div
          v-if="alert.urgency.toLowerCase() === 'immediate'"
          class="topbar-quickview-icon"
        >
          <font-awesome-icon
            icon="exclamation-triangle"
            size="2x"
            class="actions-urgency"
          />
        </div>
        <div
          v-if="!single"
          class="topbar-action"
        >
          <template-button
            event-value="details"
            type="primary"
            @button:click="toggleExpandMore"
          >
            Details
          </template-button>
        </div>
        <div class="topbar-action">
          <template-button
            event-value="xml"
            @button:click="toggleExpandMore"
          >
            XML
          </template-button>
        </div>
      </div>
      <div
        v-if="expandMore === 'xml'"
        class="expand-xml"
      >
        <app-atom
          :query="{id: alert.id}"
          owner="alert"
        />
      </div>
      <h2 class="event">
        {{ alert.event }}
      </h2>
      <p class="activeDates">
        <span class="issued">
          {{ issuanceTime }}
        </span>
        <span
          v-if="alert.vtec === null || alert.ends !== null"
          class="through"
        >
          through
        </span>
        <span class="expires">
          {{ endingTime }}
        </span>
      </p>
      <div
        v-if="expandMore === 'details' || single"
        class="expand-details"
      >
        <div class="expand-details-raw">
          <div
            v-if="alert.headline"
            class="expand-details-headline"
          ><!-- eslint-disable-line vue/multiline-html-element-content-newline -->{{ alert.headline }}
          </div>
          <div
            v-if="alert.description"
            class="expand-details-description"
          ><!-- eslint-disable-line vue/multiline-html-element-content-newline -->{{ alert.description }}
          </div>
          <div
            v-if="alert.instruction"
            class="expand-details-instruction"
          >
            <h3>Instructions</h3>{{ alert.instruction }}
          </div>
        </div>
        <div class="expand-details-info">
          <div class="expand-details-info-set">
            <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
            <div class="expand-details-info-label">Sender</div>
            <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
            <div class="expand-details-info-data"><a :href="`mailto:${alert.sender}`">{{ alert.senderName }}</a></div>
          </div>
          <div class="expand-details-info-set">
            <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
            <div class="expand-details-info-label">Sent</div>
            <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
            <div class="expand-details-info-data">{{ issuanceTime }}</div>
          </div>
          <div
            v-if="alert.references && alert.references.length > 0"
            class="expand-details-info-set"
          >
            <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
            <div class="expand-details-info-label">Updates</div>
            <div class="expand-details-info-data">
              <ul class="expand-details-references">
                <li
                  v-for="reference in alert.references"
                  :key="`${alert.id}-ref-${reference.identifier}`"
                  class="expand-details-reference"
                >
                  <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
                  <router-link :to="{ route: '/', query: { id: reference.identifier } }">{{ reference.identifier }}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="affectedAreas">
        <p class="affectedArea">
          {{ affectedAreas }}
        </p>
        <p
          v-if="affectedZones.length !== affectedZonesFiltered.length"
          class="affectedAreaNotice"
        >
          <template v-if="filterAffectedAreas">
            <i>Zone names limited by search criteria.</i>
            <a
              :href="`#fa-all-${alert.id}`"
              class="affected-area-toggle"
              @click.prevent="toggleFilterAffectedAreas"
            >
              View all zone names.
            </a>
          </template>
          <template v-else>
            <i>Including all zone names.</i>
            <a
              :href="`#fa-limit-${alert.id}`"
              class="affected-area-toggle"
              @click.prevent="toggleFilterAffectedAreas"
            >
              Limit zone names to search criteria.
            </a>
          </template>
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import AppAtom from './Atom.vue'

library.add(faExclamationTriangle)

export default {
  components: {
    AppAtom,
    FontAwesomeIcon
  },
  props: {
    alert: {
      type: Object,
      required: true
    },
    single: {
      type: Boolean,
      default: false
    },
    zones: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      expandMore: null,
      filterAffectedAreas: true
    }
  },
  computed: {
    affectedAreas () {
      // If the zone lists are the same length, nothing was filtered out.
      if (this.affectedZones.length === this.affectedZonesFiltered.length || !this.filterAffectedAreas) {
        return this.alert.areaDesc
      }

      return this.affectedZonesFiltered.map(zone => `${zone.name}, ${zone.state}`).join('; ')
    },
    affectedZones () {
      return this.alert.affectedZones.map(url => url.substring(url.lastIndexOf('/') + 1))
    },
    affectedZonesFiltered () {
      // If no zones have been provided yet, don't do any filtering.
      if (Object.keys(this.zones).length === 0) {
        return this.affectedZones
      }

      return this.affectedZones.filter(function (zoneId) {
        return this.zones.hasOwnProperty(zoneId)
      }.bind(this)).map(function (zoneId) {
        return this.zones[zoneId]
      }.bind(this))
    },
    endingTime () {
      if (this.alert.vtec === null) {
        return this.alert.expiration.toLocal().toFormat('L/d/yyyy HH:mm ZZZZ')
      }
      if (this.alert.ends !== null) {
        return this.alert.ends.toLocal().toFormat('L/d/yyyy HH:mm ZZZZ')
      }
      return 'until further notice'
    },
    issuanceTime () {
      return this.alert.issuance.toLocal().toFormat('L/d/yyyy HH:mm ZZZZ')
    }
  },
  methods: {
    expand (option) {
      this.expandMore = option
    },
    toggleExpandMore (id) {
      this.expandMore = this.expandMore !== id ? id : null
    },
    toggleFilterAffectedAreas (id) {
      this.filterAffectedAreas = !this.filterAffectedAreas
    }
  }
}
</script>

<style scoped>
.summary {
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  margin-bottom: 1.5em;
  padding: 0;
  background-color: #fdfdfd;
  border: 1px solid #ccc;
  font-size: 13px;
}

.summary p {
  margin: 0 0 10px 0;
  padding: 0;
}

.summary h2 {
  margin: 0;
  padding: 0;
  font-size: 1.4em;
  color: #000;
}

.summary h3 {
  margin: 0 0 20px 0;
  padding: 0;
  color: #000;
}

.summary h4 {
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 1em;
  font-weight: bold;
}

.summary .content {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em;
}

.summary .topbar {
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
}

.summary .topbar-quickview {
  flex: 1;
  font-weight: bold;
  font-size: 120%;
}

.summary .topbar-quickview-icon {
  align-self: center;
  padding-right: 10px;
}

.summary .topbar-quickview span.urgency {
  color: #00a3e3;
}

.summary .topbar-quickview span.Immediate {
  color: red !important;
}

.summary .topbar-quickview .topbar-quickview-value {
  display: block;
}

.summary .topbar-quickview-divider {
  display: none;
}

@media only screen and (min-width: 400px) {
  .summary .topbar {
    margin-bottom: 4px;
  }

  .summary .topbar-quickview .topbar-quickview-value {
    display: initial;
  }

  .summary .topbar-quickview-divider {
    display: initial;
  }
}

.summary .topbar-action {
  flex: 0 0;
}

.summary .activeDates {
  font-size: inherit;
}

.summary .expires,
.summary .detailLink {
  margin: 1em 0;
}

.summary .detailLink a:link {
  text-decoration: underline;
  color: #135897;
}

.summary .detailLink a:hover {
  text-decoration: none;
}

.expand-xml {
  position: relative;
  left: -10px;
  width: calc(100% + 20px);
  padding: 20px;
  background: #efefef;
  margin: 10px 0;
  border-bottom: 1px solid #cdcdcd;
}

.expand-xml-uri {
  font-family: monospace;
  margin: 5px 0 10px;
  font-size: 95%;
}

.expand-xml-note {
  font-style: italic;
  font-size: 90%;
}

.expand-xml-type {
  font-family: monospace;
  font-size: 90%;
  font-weight: bold;
}

.expand-details {
  margin-bottom: 20px;
}

.expand-details-raw {
  left: -10px;
  position: relative;
  width: calc(100% + 20px);
  font-family: monospace;
  white-space: pre-line;
  background: #efefef;
  padding: 20px;
}

.expand-details-headline {
  margin-bottom: 10px;
}

.expand-details-description {
  margin-bottom: 10px;
}

.expand-details h3 {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 110%;
}

.expand-details-references {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.expand-details-reference {
  margin-bottom: 4px;
}

.expand-details-info {
  display: table;
  margin-top: 10px;
}

.expand-details-info-set {
  display: table-row;
}

.expand-details-info-label,
.expand-details-info-data {
  display: table-cell;
  padding: 4px 10px 4px 0;
}

.expand-details-info-label {
  font-weight: bold;
}
</style>
