<template>
  <template-page :panel.sync="panel">
    <router-view />
    <template slot="description">
      <p>The NWS Alerts site provides watches, warnings, advisories, and similar products. Visit <a href="https://vlab.noaa.gov/web/nws-common-alerting-protocol/overview">NWS Common Alerting Protocol for CAP v1.2</a> for updated documentation.</p>
      <p>It is recommended to start by selecting your location. Selecting search without a location will assume all locations. Once the location is selected and the alerts are displayed, you can bookmark the URL for future searches.</p>
    </template>
    <template slot="guide">
      <p>Alerts based on your latitude/longitude generates the best results. Zone is otherwise recommended. Use county when searching for the following events:</p>
      <ul>
        <li>TOR (Tornado Warning)</li>
        <li>TOA (Tornado Watch)</li>
        <li>SVR (Severe Thunderstorm Warning)</li>
        <li>SVA (Severe Thunderstorm Watch)</li>
        <li>EWW (Extreme Wind Warning)</li>
        <li>SVS (Severe Weather Statement)</li>
        <li>SMW (Special Marine Warning)</li>
        <li>MWS (Marine Weather Statement)</li>
        <li>FFW (Convective Flash Flood Warning)</li>
        <li>FFS (Convective Flash Flood Statement)</li>
        <li>FLW (Areal Flood Warning)</li>
        <li>FLS (Areal Flood Advisory or Areal Flood Statement)</li>
        <li>SPS (Special Weather Statement)</li>
      </ul>
      <p>NWS Zone and county maps and listings are available from the <a href="https://www.nws.noaa.gov/mirs/">Management Information Retrieval System</a> and the <a href="https://www.nws.noaa.gov/geodata/">GIS Database</a>.</p>
    </template>
  </template-page>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  computed: {
    panel: {
      get () { return this.$store.getters.panel },
      set (value) { this.$store.commit('panel', value) }
    }
  },
  async mounted () {
    window.addEventListener('resize', this.evaluateWindowSize)
    this.evaluateWindowSize()

    await this.loadZones()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.evaluateWindowSize)
  },
  methods: {
    ...mapActions([
      'loadZones'
    ]),
    evaluateWindowSize () {
      // TODO: Evaluate if still needed
    }
  }
}
</script>
