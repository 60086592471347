import deepmerge from 'deepmerge'

import { Api } from './api'
import * as loaders from './loaders'
import { getLogger } from '../log'
import { SchemaValidator } from '../schema'

export * from './entities'
export * from './sections'

const DEFAULTS = {
  baseUrl: 'https://api.weather.gov',
  instanceName: '$api'
}

const CONFIG_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  $id: '/config',
  type: 'object',
  additionalProperties: false,
  required: ['baseUrl', 'instanceName'],
  properties: {
    baseUrl: { type: 'string' },
    instanceName: { type: 'string' }
  }
}

function plugin (Vue, options = {}) {
  options = deepmerge(DEFAULTS, options)

  const validator = new SchemaValidator({ schemas: [CONFIG_SCHEMA] })
  validator.validate('/config', options)

  Vue.prototype[options.instanceName] = new Api(options.baseUrl)

  getLogger().debug(
    'Public API plugin initialized:' +
    `\n  Instance name: ${options.instanceName}` +
    `\n  URL for weather.gov public API: ${options.baseUrl}`
  )
}

export default plugin

export {
  Api,
  loaders,
  DEFAULTS
}
