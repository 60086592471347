<template>
  <div class="input">
    <v-datetime
      v-model="inputValue"
      button-color="#0099D8"
      color="#28282A"
      format="YYYY-MM-DD[T]HH:mm:ssZ"
      :hint="placeholder"
      :label="placeholder"
      :max-date="max || undefined"
      :min-date="min || undefined"
      :no-clear-button="true"
    />
  </div>
</template>

<script>
import VDatetime from 'vue-ctk-date-time-picker'

export default {
  components: {
    VDatetime
  },
  props: {
    clearInputOnClose: {
      type: Boolean,
      default: true
    },
    max: {
      type: String,
      default: null
    },
    min: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    inputValue: {
      get () { return this.value || undefined },
      set (value) { this.$emit('input', value || null) }
    },
    isDrawerOpen () {
      return this.drawer.isOpen()
    },
    isValueSet () {
      return this.value !== null
    }
  },
  inject: {
    drawer: 'drawer'
  },
  watch: {
    isDrawerOpen () {
      if (!this.isDrawerOpen && this.clearInputOnClose) {
        this.clear()
      }
    },
    value: {
      immediate: true,
      handler () {
        if (!this.isDrawerOpen && this.isValueSet) {
          this.drawer.open()
        }
      }
    }
  },
  methods: {
    clear () {
      this.$emit('input', null)
    }
  }
}
</script>

<style scoped>
.input {
  background-color: #efefef;
  color: #333;
  height: 40px;
}

.input input {
  height: 100%;
  width: 100%;
  padding-left: 12px;
}
</style>
