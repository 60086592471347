import Id from '../components/pages/Id.vue'
import Location from '../components/pages/Location.vue'
import Search from '../components/pages/Search.vue'

export default [
  {
    path: '/',
    component: Location,
    localRoutesTitle: 'Location',
    localRoutesOrder: 1,
    props: route => ({ query: route.query })
  },
  {
    path: '/id/:id?',
    component: Id,
    localRoutesTitle: 'Alert Id',
    localRoutesOrder: 2,
    localRouterDefaultPath: '/id',
    props: route => ({ query: route.query })
  },
  {
    path: '/search',
    component: Search,
    localRoutesTitle: 'Search',
    localRoutesOrder: 3,
    props: route => ({ query: route.query })
  }
]
