import Vue from 'vue'

import { LoadingError, ResourceLoader } from '../../http'

export class ApiResourceLoader extends ResourceLoader {
  async load (url, options, handler) {
    options = Object.assign({
      headers: { 'Accept': 'application/ld+json' },
      responseType: 'json'
    }, options)

    return super.load(url, options, handler)
  }
}

export class ApiLoader {
  constructor (url, transformer, options = {}, initialValue = undefined) {
    this.__data = Vue.observable({
      attributes: {},
      error: null,
      promise: null,
      next: url,
      result: initialValue
    })
    this.__options = options
    this.__transformer = transformer
    this.__url = url
  }

  get attributes () {
    return this.__data.attributes
  }

  get done () {
    return this.__data.next === null
  }

  get error () {
    return this.__data.error
  }

  get loading () {
    return this.__data.promise !== null
  }

  get result () {
    return this.__data.result
  }

  get url () {
    return this.__url
  }

  async load () {
    if (this.done) {
      return
    }

    if (this.__data.promise) {
      return this.__data.promise
    }

    const loader = new ApiResourceLoader()
    this.__data.promise = loader.load(this.__url, this.__options, this.__handle.bind(this))

    try {
      await this.__data.promise
      this.__data.next = null
    } catch (err) {
      this.__data.error = new LoadingError(`Error requesting data from ${this.__url}`, err)
    } finally {
      this.__data.promise = null
    }
  }

  __handle (response) {
    const transformation = this.__transformer
      ? this.__transformer(response)
      : new ApiTransformationResult(response.data)

    this.__data.attributes = transformation.attributes
    this.__data.result = transformation.result
  }
}

export class ApiCollectionLoader {
  constructor (url, transformer, options) {
    this.__data = Vue.observable({
      attributes: {},
      error: null,
      promise: null,
      result: [],
      next: url
    })
    this.__options = options
    this.__transformer = transformer
    this.__url = url
  }

  get attributes () {
    return this.__data.attributes
  }

  get done () {
    return this.__data.next === null
  }

  get error () {
    return this.__data.error
  }

  get loading () {
    return this.__data.promise !== null
  }

  get result () {
    return this.__data.result
  }

  get url () {
    return this.__url
  }

  async load () {
    if (this.done) {
      return
    }

    if (this.__data.promise) {
      return this.__data.promise
    }

    const loader = new ApiResourceLoader()
    this.__data.promise = loader.load(this.__data.next, this.__options, this.__handle.bind(this))

    try {
      await this.__data.promise
    } catch (err) {
      this.__data.error = new LoadingError(`Error requesting data from ${this.__url}`, err)
    } finally {
      this.__data.promise = null
    }
  }

  __handle (response) {
    const transformation = this.__transformer(response)

    if (response.data.hasOwnProperty('pagination') && response.data.pagination.hasOwnProperty('next')) {
      this.__data.next = response.data.pagination.next
    } else {
      this.__data.next = null
    }

    this.__data.attributes = transformation.attributes
    this.__data.result = this.__data.result.concat(transformation.result)
  }
}

export class ApiTransformationResult {
  constructor (result, attributes = {}) {
    this.__attributes = attributes
    this.__result = result
  }

  get attributes () {
    return this.__attributes
  }

  get result () {
    return this.__result
  }
}
