<template>
  <article>
    <div class="content">
      <slot
        class="description"
        name="description"
      />
      <template-menu-list
        v-if="localRoutes && localRoutes.length > 1"
        :items="localRoutes"
      />
      <p
        v-else
        class="page-message"
      >
        There are no additional pages on this site. Use the "Explore More Weather" button for other weather information.
      </p>
      <div v-if="hasGuideSlotContent">
        <h2 class="guide">
          Quick Guide
        </h2>
        <slot name="guide" />
      </div>
    </div>
  </article>
</template>

<script>
import { getConfig } from '../../config'

export default {
  computed: {
    localRoutes () {
      return getConfig().localRoutes
    },
    hasGuideSlotContent () {
      return !!this.$slots['guide']
    }
  }
}
</script>

<style scoped>
.content {
  padding: 20px 20px 20px 10px;
}

.page-message {
  margin-top: 20px;
}

h2 {
  font-size: 130%;
}
</style>
