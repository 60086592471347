<template>
  <div class="container">
    <div
      v-if="alerts.length > 0"
      class="alerts"
    >
      <app-alert-summary
        v-for="alert in displayedAlerts"
        ref="alerts"
        :key="alert.id"
        :alert="alert"
        :zones="zones"
        :single="isSingleAlert"
      />
    </div>
    <div
      v-if="status"
      class="status"
    >
      <font-awesome-icon
        class="status-icon"
        :icon="status.icon"
        :spin="status.spin"
      />
      {{ status.message }}
    </div>
    <template-button
      v-else-if="!loader.done"
      event-value="alerts"
      @button:click="loadMore"
    >
      Load More
    </template-button>
  </div>
</template>

<script>
import { faCircleNotch, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import AppAlertSummary from './AlertSummary.vue'

library.add(faCheck, faCircleNotch, faTimes)

const ALERT_CHUNK_SIZE = 10
const ALERT_CHUNK_DELAY = 20

export default {
  components: {
    AppAlertSummary,
    FontAwesomeIcon
  },
  props: {
    loader: {
      type: Object,
      required: true
    },
    lookupById: {
      type: Boolean,
      default: false
    },
    zones: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      displayedAlerts: []
    }
  },
  computed: {
    alerts () {
      const alerts = this.loader.result
      if (!alerts) {
        return []
      }
      if (!Array.isArray(alerts)) {
        return [alerts]
      }
      return Array.from(alerts.reduce((map, alert) => {
        if (!map.has(alert.id)) {
          map.set(alert.id, alert)
        }
        return map
      }, new Map()).values())
    },
    isSingleAlert () {
      if (this.lookupById && this.displayedAlerts.length === 1) {
        return true
      }
      return false
    },
    loading () {
      return this.loader === null || this.loader.loading || this.displayedAlerts.length < this.alerts.length
    },
    status () {
      if (this.loading) {
        return { icon: 'circle-notch', message: 'Loading alerts...', spin: true }
      }
      if (this.error) {
        return { icon: 'times', message: 'There was an error loading the alerts.', spin: false }
      }
      if (this.alerts.length === 0) {
        return { icon: 'times', message: 'No alerts found for the search criteria.', spin: false }
      }
      if (this.loader.done && !this.lookupById) {
        return { icon: 'check', message: 'All alerts for search criteria have loaded.', spin: false }
      }
      return null
    }
  },
  watch: {
    alerts: {
      immediate: true,
      handler () {
        this.displayedAlerts = []
        this.displayAlerts(this.alerts)
      }
    }
  },
  methods: {
    displayAlerts (alerts) {
      if (this.alerts === alerts && this.displayedAlerts.length < this.alerts.length) {
        this.displayedAlerts = this.alerts.slice(0, this.displayedAlerts.length + ALERT_CHUNK_SIZE)
        setTimeout(function () {
          this.displayAlerts(alerts)
        }.bind(this), ALERT_CHUNK_DELAY)
      }
    },
    async loadMore () {
      await this.loader.load()
    }
  }
}
</script>

<style scoped>
.status {
  margin-top: 10px;
  padding: 20px;
  font-style: italic;
  border: 1px dashed #cdcdcd;
  border-radius: 8px;
}

.status-icon {
  color: #444;
  margin-right: 7px;
}

.updated {
  margin-top: 10px;
  font-size: 90%;
}

.no-break {
  white-space: nowrap;
}
</style>
