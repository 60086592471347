import Vue from 'vue'
import VueAsyncComputed from 'vue-async-computed'
import VueRouter from 'vue-router'

import ApiPlugin from './plugins/api'
import CriteriaPlugin from './plugins/criteria'
import LogPlugin from './plugins/log'
import FallbackPlugin from './plugins/fallback'
import TemplatePlugin from './plugins/template'

import App from './App.vue'
import routes from './router/routes'
import { createStore } from './store'
import favicon from './assets/favicon.ico'
import './app.css'

function buildLocalRoutes (routes) {
  const localRoutes = routes.map(route => {
    return {
      text: route.localRoutesTitle,
      href: route.localRouterDefaultPath || route.path,
      order: route.localRoutesOrder,
      items: route.children ? buildLocalRoutes(route.children) : []
    }
  })

  return localRoutes.sort((a, b) => a.order - b.order)
}

Vue.config.productionTip = false
Vue.use(VueAsyncComputed)
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

export function createApp (state, server = false) {
  Vue.use(FallbackPlugin)
  Vue.use(LogPlugin, { logEvents: false, disabled: server })
  Vue.use(ApiPlugin, { baseUrl: state.apiUrl })
  Vue.use(CriteriaPlugin)
  Vue.use(TemplatePlugin, {
    localRoutes: buildLocalRoutes(routes),
    weatherUrl: state.weatherUrl
  })

  const app = new Vue({
    components: { App },
    render: createElement => createElement('app'),
    router,
    store: createStore(state.apiUrl)
  })

  return { app, favicon }
}
