import { Zone } from '../entities'
import { ApiLoader, ApiCollectionLoader, ApiTransformationResult } from '../loaders'
import { getLogger } from '../../log'

export class ApiZones {
  constructor (baseUrl) {
    this.__baseUrl = baseUrl
    this.__logger = getLogger()
  }

  get (type, id) {
    const url = new URL(`${this.__baseUrl}/zones/${type}/${id}`)

    return new ApiLoader(url.href, this.__transformZone.bind(this))
  }

  search (parameters) {
    let url = new URL(`${this.__baseUrl}/zones`)

    if (parameters) {
      for (const parameter in parameters) {
        url.searchParams.set(parameter, parameters[parameter])
      }
    }

    return new ApiCollectionLoader(url.href, this.__transformZones.bind(this))
  }

  __createZoneFromProperties (properties) {
    return new Zone({
      cwa: properties.cwa,
      id: properties.id,
      name: properties.name,
      state: properties.state,
      type: properties.type
    })
  }

  __transformZone (response) {
    const entry = this.__createZoneFromProperties(response.data)
    this.__logger.debug(`Found details for ${entry.type} zone ${entry.id}`, entry)

    return new ApiTransformationResult(entry)
  }

  __transformZones (response) {
    const entries = response.data['@graph'].map(this.__createZoneFromProperties)
    this.__logger.debug(`Fetched ${Object.keys(entries).length} zones`)

    return new ApiTransformationResult(entries)
  }
}
