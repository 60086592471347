<template>
  <div>
    <app-wizard>
      <template v-slot:title>
        {{ title }}
      </template>
      <template v-slot:description>
        Please select a {{ type }} region or area to view alerts.
      </template>
      <template v-slot:options>
        <div class="filter">
          <div class="filterLabel">
            Filter {{ unitLabel }}
          </div>
          <div class="filterInput">
            <input
              ref="filter"
              v-model="filter"
            >
          </div>
        </div>
        <div
          v-if="displayUnits.length > 1"
          class="unit"
        >
          <label
            v-for="displayUnit in displayUnits"
            :key="displayUnit"
          >
            <input
              v-model="unit_"
              type="radio"
              name="unit"
              :value="displayUnit.toLowerCase()"
            >
            {{ displayUnit }}
          </label>
        </div>
        <div class="order">
          <div>
            Order by Name
          </div>
          <select
            v-model="orderDirection"
            name="orderDirection"
          >
            <option value="ASC">
              ASC
            </option>
            <option value="DESC">
              DESC
            </option>
          </select>
        </div>
        <ul class="optionsList optionsPrimaryList">
          <li>
            <a
              href="#all"
              @click.prevent="load"
            >
              All {{ type }}
            </a>
          </li>
          <li>
            <div class="optionsListLabel">
              List of {{ plural(unitLabel) }}
            </div>
            <app-wizard-option
              v-for="option in options"
              :id="option.id"
              :key="option.id"
              :expand="'option1'"
              :opened-drawer="openedDrawer"
            >
              <template #option1>
                <a
                  :href="`#${option.id}`"
                  @click.prevent="loadOption(option.id)"
                >
                  {{ option.label }}
                </a>
              </template>
              <template #option2>
                <a
                  :href="`#${option.id}-atom`"
                  @click.prevent="openDrawer(option.id, 'atom')"
                >
                  ATOM
                </a>
              </template>
              <template #option3>
                <div class="zonesOptions">
                  <a
                    :href="`#${option.id}-zones`"
                    :class="{ zonesOptionsAutoHide: quickZones.length > 0 }"
                    @click.prevent="gotoZoneWizard(option.id, option.label)"
                  >
                    Zones
                  </a>
                  <a
                    v-for="quickZone in quickZones"
                    :key="`#${option.id}-${quickZone}-quickzone`"
                    :href="`#${option.id}-${quickZone}-zones`"
                    class="zonesOptionsQuickZone"
                    @click.prevent="gotoZoneWizard(option.id, option.label, quickZone)"
                  >
                    {{ convertToLabel(quickZone) }} Zones
                  </a>
                </div>
              </template>
              <template #drawer-atom>
                <app-atom
                  owner="location"
                  :query="{[unit_]: option.id}"
                />
              </template>
            </app-wizard-option>
            <div
              v-if="options.length === 0"
              class="emptyset"
            >
              <!-- eslint-disable-next-line vue/max-attributes-per-line -->
              None found (<a href="#clearfilter" @click.prevent="resetFilter">Clear Filter</a>)
            </div>
          </li>
        </ul>
      </template>
    </app-wizard>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import AppWizard from './Wizard.vue'
import AppWizardOption from './WizardOption.vue'
import AppAtom from '../Atom.vue'

export default {
  components: {
    AppWizard,
    AppWizardOption,
    AppAtom
  },
  props: {
    areaOptions: {
      type: [Array],
      required: true
    },
    quickZones: {
      type: [Array],
      default: () => []
    },
    regionOptions: {
      type: [Array],
      required: true
    },
    unit: {
      type: [String],
      default: 'area'
    },
    type: {
      type: [String],
      required: true
    }
  },
  data () {
    return {
      filter: null,
      openedDrawer: null,
      order: 'label',
      orderDirection: 'ASC',
      unit_: null, // populated from unit parameter via watch
      availableUnits: {
        area: false,
        region: false
      }
    }
  },
  computed: {
    displayUnits () {
      let units = []
      for (let unit in this.availableUnits) {
        if (this.availableUnits[unit]) {
          units.push(this.convertToLabel(unit))
        }
      }
      return units
    },
    options () {
      const fullOptions = this.unit_ === 'area' ? this.areaOptions : this.regionOptions
      let filteredOptions
      if (this.filter === null || this.filter.trim().length === 0) {
        filteredOptions = fullOptions
      } else {
        filteredOptions = fullOptions.filter(item => {
          const lcaseFilter = this.filter.trim().toLowerCase()
          const lcaseId = item[0].toLowerCase()
          const lcaseLabel = item[1].toLowerCase()
          return lcaseId.indexOf(lcaseFilter) >= 0 || lcaseLabel.indexOf(lcaseFilter) >= 0
        })
      }

      filteredOptions = filteredOptions.map(item => {
        return {
          id: item[0],
          label: item[1]
        }
      })

      const sortDir = this.orderDirection === 'DESC' ? -1 : 1
      return filteredOptions.sort((a, b) => {
        return sortDir * a[this.order].localeCompare(b[this.order])
      })
    },
    title () {
      return `${this.typeLabel} ${this.displayUnits.join('/')}`
    },
    typeLabel () {
      return this.convertToLabel(this.type)
    },
    unitLabel () {
      return this.convertToLabel(this.unit_)
    }
  },
  watch: {
    areaOptions: {
      immediate: true,
      handler () {
        if (this.areaOptions.length > 0) {
          this.availableUnits.area = true
        }
      }
    },
    filter () {
      this.openedDrawer = null
    },
    regionOptions: {
      immediate: true,
      handler () {
        if (this.regionOptions.length > 0) {
          this.availableUnits.region = true
        }
      }
    },
    unit: {
      immediate: true,
      handler () {
        this.openedDrawer = null
        this.unit_ = this.unit
      }
    }
  },
  beforeMount () {
    this.addWizardPath({
      label: `${this.typeLabel}`,
      wizard: this.type
    })
  },
  mounted () {
    this.focusFilter()
  },
  methods: {
    ...mapMutations([
      'addWizardPath'
    ]),
    ...mapMutations('criteria', {
      setArea: 'areas',
      setRegion: 'regions',
      setLand: 'land',
      setMarine: 'marine',
      resetAllParams: 'reset'
    }),
    convertToLabel (word) {
      return word[0].toUpperCase() + word.slice(1)
    },
    createQuery (param, value) {
      let query = {}
      query[param] = value
      return query
    },
    focusFilter () {
      this.$refs.filter.focus()
    },
    gotoZoneWizard (code, label, type) {
      this.$emit('goto', 'zone', {
        type: type,
        unitCode: code,
        unitLabel: label,
        unitType: this.type,
        unitUnit: this.unit_
      })
    },
    load () {
      this.resetAllParams()
      if (this.type === 'marine') {
        this.setMarine(true)
      } else {
        this.setLand(true)
      }
      this.$emit('search')
    },
    loadOption (id) {
      this.resetAllParams()
      if (this.unit_ === 'area') {
        this.setArea([id])
      } else {
        this.setRegion([id])
      }
      this.$emit('search')
    },
    resetFilter () {
      this.filter = null
    },
    openDrawer (optionId, drawerId) {
      this.openedDrawer = {
        id: optionId,
        drawer: drawerId
      }
    },
    plural (word) {
      return word + 's'
    }
  }
}
</script>

<style scoped>
.optionsList {
  margin-left: 0;
  list-style-type: none;
  padding-left: 20px;
}

.optionsListLabel {
  font-size: 100%;
}

.optionsList .zonesOptions {
  display: flex;
  gap: 10px;
}

.optionsList .zonesOptionsQuickZone {
  display: none;
}

@media (min-width: 520px) {
  .optionsList .zonesOptionsAutoHide {
    display: none;
  }

  .optionsList .zonesOptionsQuickZone {
    display: initial;
  }
}

.optionsPrimaryList {
  padding-left: 0;
}

.emptyset {
  font-style: italic;
}

.filter {
  max-width: 300%;
}

.filter input {
  height: 40px;
  font-size: 102%;
  padding: 10px;
}

.unit {
  margin-top: 10px;
}

.order {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.order > select {
  display: block;
  font-size: 16px;
}
</style>
