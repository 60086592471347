import { ApiAlerts, ApiZones } from './sections'

export class Api {
  constructor (baseUrl) {
    this.__apiAlerts = new ApiAlerts(baseUrl)
    this.__apiZones = new ApiZones(baseUrl)
    this.__baseUrl = baseUrl
  }

  get alerts () {
    return this.__apiAlerts
  }

  get baseUrl () {
    return this.__baseUrl
  }

  get zones () {
    return this.__apiZones
  }
}
