import Vue from 'vue'
import Vuex from 'vuex'

import criteria from './modules/criteria'
import { Api } from '../plugins/api'

Vue.use(Vuex)

export function createStore (apiUrl) {
  const api = new Api(apiUrl)

  return new Vuex.Store({
    modules: {
      criteria: criteria
    },
    state: {
      alertsLoader: null,
      apiUrl: apiUrl,
      panel: null,
      wizard: 'location',
      wizardPath: [],
      wizardOptions: {},
      zonesLoader: null
    },
    mutations: {
      alertsLoader (state, value) {
        state.alertsLoader = value
      },
      panel (state, value) {
        state.panel = value
      },
      wizard (state, value) {
        state.wizard = value
      },
      wizardOptions (state, { wizard, options }) {
        state.wizardOptions[wizard] = options
      },
      addWizardPath (state, value) {
        state.wizardPath.push(value)
      },
      gotoWizard (state, value) {
        // when returning to a wizard, drop itself and all wizards after it
        for (let x = 0; x < state.wizardPath.length; x++) {
          if (state.wizardPath[x].wizard === value) {
            state.wizardPath.splice(x) // second param omitted to go to end of array
            break
          }
        }
        state.wizard = value
      },
      hideWizard (state) {
        state.wizard = null
      },
      resetWizardPath (state) {
        state.wizardPath = []
      }
    },
    actions: {
      async loadZones ({ state }, options) {
        state.zonesLoader = api.zones.search(options)
        await state.zonesLoader.load()
      }
    },
    getters: {
      alertsLoader: state => state.alertsLoader,
      apiUrl: state => state.apiUrl,
      criteria: state => state.criteria,
      panel: state => state.panel,
      wizard: state => state.wizard,
      wizardPath: state => state.wizardPath,
      selectedWizardOptions: (state) => state.wizardOptions[state.wizard],
      zones: state => state.zonesLoader ? state.zonesLoader.result : [],
      zonesLoader: state => state.zonesLoader
    }
  })
}
