<template>
  <div
    class="banner"
    :style="bannerShadowStyle"
  >
    <div class="logo">
      <img
        :src="imageLogo"
        alt="NOAA/NWS Logo"
      >
    </div>
    <div class="title">
      <span class="title-short">NWS</span>
      <span class="title-long">NATIONAL WEATHER SERVICE</span>
      <span class="title-product">| Alerts</span>
    </div>
    <div class="menu">
      <div
        class="button"
        @click="$emit('panel:toggle', 'local')"
      >
        <font-awesome-icon icon="chevron-down" />
      </div>
      <div
        class="button"
        @click="$emit('panel:toggle', 'help')"
      >
        <font-awesome-icon icon="question" />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce-promise'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import imageLogo from '../assets/header-logo-medium.png'

library.add(faChevronDown, faQuestion)

export default {
  components: {
    FontAwesomeIcon
  },
  data () {
    return {
      imageLogo: imageLogo,
      bannerShadow: 0.2,
      bannerShadowScrollSpread: 50,
      scrollY: 0
    }
  },
  computed: {
    bannerShadowStyle () {
      const opacity = Math.min(this.scrollY * this.bannerShadow / this.bannerShadowScrollSpread, this.bannerShadow)
      return {
        'box-shadow': `0 3px 5px 0px rgb(0, 0, 0, ${opacity})`
      }
    }
  },
  mounted () {
    this.handleDebouncedScroll = debounce(this.handleScroll, 2)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },
  methods: {
    handleScroll (event) {
      this.scrollY = window.scrollY
    }
  }
}
</script>

<style scoped>
.banner {
  box-sizing: content-box;
  font-family: prox-bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 46px;
  background: white;
  color: #333;
  line-height: 40px;
  padding: 0 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  z-index: 100000;
}

.logo {
  display: inline-block;
  width: 63px;
  height: 30px;
}

.logo img {
  width: 63px;
}

.title {
  font-size: 18px;
  margin-left: 6px;
}

.title-product {
  font-size: 80%;
  font-family: prox-regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.title-long {
  display: none;
}

@media screen and (min-width: 530px) {
  .title-short {
    display: none;
  }

  .title-long {
    display: initial;
  }
}

@media screen and (min-width: 630px) {
  .title {
    font-size: 20px;
  }

  .title-long {
    display: initial;
  }
}

.menu {
  margin-left: 20px;
  display: flex;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #0099d8;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.menu .button {
  width: 50px;
  height: 46px;
}
</style>
