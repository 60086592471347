<template>
  <div>
    <app-wizard>
      <template v-slot:title>
        Location
      </template>
      <template v-slot:description>
        Select a location option to begin your search for alerts.
      </template>
      <template v-slot:options>
        <ul id="locationOptions">
          <li>
            <div class="pointTitle">
              <span>
                Coordinates
              </span>
              <span v-if="geolocationEnabled">
                or
                <a
                  href="#pointgeo"
                  @click.prevent="getGeolocation"
                >
                  use my location
                </a>
              </span>
              <div
                v-if="geolocationMessage"
                class="pointMessage"
              >
                {{ geolocationMessage }}
              </div>
            </div>
            <div class="point">
              <div class="pointSegment">
                <div class="pointSegmentLabel">
                  Lon
                </div>
                <div class="filterInput">
                  <input
                    ref="filter"
                    v-model="pointLon"
                    size="6"
                  >
                </div>
              </div>
              <div class="pointSegment">
                <div class="pointSegmentLabel">
                  Lat
                </div>
                <div class="filterInput">
                  <input
                    ref="filter"
                    v-model="pointLat"
                    size="6"
                  >
                </div>
                <div>
                  <button
                    class="pointButton"
                    @click.prevent="loadPoint"
                  >
                    Go
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li>
            <a
              href="#"
              @click.prevent="goto('land')"
            >
              Land areas with zones
            </a>
          </li>
          <li>
            <a
              href="#"
              @click.prevent="gotoWizard('marine')"
            >
              Marine regions/areas with zones
            </a>
          </li>
          <li>
            <a
              href="#"
              @click.prevent="loadAllLocations"
            >
              All locations
            </a>
          </li>
        </ul>
      </template>
    </app-wizard>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import AppWizard from './Wizard.vue'

export default {
  components: {
    AppWizard
  },
  data () {
    return {
      geolocationResult: null,
      pointLat: null,
      pointLon: null
    }
  },
  computed: {
    geolocationEnabled () {
      return 'geolocation' in navigator
    },
    geolocationMessage () {
      if (this.geolocationEnabled) {
        switch (this.geolocationResult) {
          case 1:
            return 'Geolocation permission denied'
          case 2:
            return 'Failed to get geolocation'
          case 3:
            return 'Failed to get geolocation'
          default:
            return null
        }
      }

      return '(device location not available)'
    }
  },
  beforeMount () {
    this.resetWizardPath()
    this.addWizardPath({
      label: 'Location',
      wizard: 'location'
    })
  },
  methods: {
    ...mapMutations([
      'addWizardPath',
      'gotoWizard',
      'resetWizardPath'
    ]),
    ...mapMutations('criteria', {
      setPoint: 'point',
      resetAllParams: 'reset'
    }),
    getGeolocation () {
      this.geolocationResult = null

      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.pointLat = Math.round((position.coords.latitude + Number.EPSILON) * 10000) / 10000
          this.pointLon = Math.round((position.coords.longitude + Number.EPSILON) * 10000) / 10000
          this.loadPoint()
        },
        (error) => {
          this.geolocationResult = error.code
        }
      )
    },
    goto (wizard) {
      this.$emit('goto', wizard)
    },
    loadAllLocations () {
      this.resetAllParams()
      this.$emit('search')
    },
    loadPoint () {
      this.resetAllParams()
      this.setPoint([this.pointLon, this.pointLat])
      this.$emit('search')
    }
  }
}
</script>

<style scoped>
#locationOptions {
  list-style-type: none;
  padding-left: 0;
}

#locationOptions li {
  margin-top: 20px;
}

.point {
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
  align-items: center;
  line-height: 38px;
}

.pointSegment {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
}

.pointSegmentLabel {
  width: 24px;
}

.point input {
  height: 30px;
  font-size: 102%;
  padding: 10px;
}

.pointMessage {
  font-style: italic;
}

.pointButton {
  width: 60px;
  height: 30px;
}
</style>
