import { DateTime } from 'luxon'

import { CERTAINTY, SEVERITY, URGENCY } from '../../constants'

function createDefaultState () {
  return {
    id: null,
    time: {
      history: false,
      start: null,
      end: null
    },
    location: {
      areas: [],
      land: false,
      marine: false,
      point: null,
      regions: [],
      zones: []
    },
    events: [],
    certainty: [],
    severity: [],
    urgency: []
  }
}

export default {
  namespaced: true,
  state: createDefaultState(),
  mutations: {
    id (state, value) {
      state.id = value
    },
    // Time
    history (state, value) {
      state.time.history = Boolean(value)
    },
    start (state, value) {
      if (value !== null && !DateTime.fromISO(value)) {
        throw new Error('Start time must be null or an ISO8601 string')
      }
      state.time.start = value
    },
    end (state, value) {
      if (value !== null && !DateTime.fromISO(value)) {
        throw new Error('End time must be null or an ISO8601 string')
      }
      state.time.end = value
    },
    // Location
    areas (state, value) {
      if (!Array.isArray(value)) {
        throw new Error(`Areas must be provided as an array`)
      }
      state.location.areas = value
    },
    land (state, value) {
      state.location.land = Boolean(value)
    },
    marine (state, value) {
      state.location.marine = Boolean(value)
    },
    point (state, value) {
      if (value !== null) {
        if (!Array.isArray(value) || value.length !== 2) {
          throw new Error('Point must be null or an array of length 2')
        }
        if (value[0] < -180 || value[0] > 180) {
          throw new Error('Longitude must be between -180 and 180')
        }
        if (value[1] < -90 || value[1] > 90) {
          throw new Error('Latitude must be between -90 and 90')
        }
      }
      state.location.point = value
    },
    regions (state, value) {
      if (!Array.isArray(value)) {
        throw new Error(`Regions must be provided as an array`)
      }
      state.location.regions = value
    },
    zones (state, value) {
      if (!Array.isArray(value)) {
        throw new Error(`Zones must be provided as an array`)
      }
      state.location.zones = value
    },
    // Other
    events (state, value) {
      if (!Array.isArray(value)) {
        throw new Error(`Events must be provided as an array`)
      }
      state.events = value
    },
    certainty (state, value) {
      if (!Array.isArray(value)) {
        throw new Error(`Certainty must be provided as an array`)
      }
      for (const certainty of value) {
        if (!CERTAINTY.includes(certainty)) {
          throw new Error(`Invalid certainty level: ${certainty}`)
        }
      }
      state.certainty = value
    },
    severity (state, value) {
      if (!Array.isArray(value)) {
        throw new Error(`Severity must be provided as an array`)
      }
      for (const severity of value) {
        if (!SEVERITY.includes(severity)) {
          throw new Error(`Invalid severity level: ${severity}`)
        }
      }
      state.severity = value
    },
    urgency (state, value) {
      if (!Array.isArray(value)) {
        throw new Error(`Urgency must be provided as an array`)
      }
      for (const urgency of value) {
        if (!URGENCY.includes(urgency)) {
          throw new Error(`Invalid urgency level: ${urgency}`)
        }
      }
      state.urgency = value
    },
    // Full or root levels reset
    reset (state, value) {
      if (value) {
        state[value] = createDefaultState()[value]
      } else {
        Object.assign(state, createDefaultState())
      }
    }
  },
  actions: {
    setToQuery ({ commit, getters }, query) {
      commit('reset')
      if (query.id) {
        // ID
        commit('id', query.id)
      } else {
        // Time
        commit('history', query.history)
        commit('start', query.start)
        commit('end', query.end)
        // Location
        commit('areas', query.area ? query.area.split(',') : [])
        commit('land', query.region_type === 'land')
        commit('marine', query.region_type === 'marine')
        commit('regions', query.region ? query.region.split(',') : [])
        commit('zones', query.zone ? query.zone.split(',') : [])
        if (query.point) {
          const [latitude, longitude] = query.point.split(',', 2)
          commit('point', [longitude, latitude])
        }
        // Other
        commit('events', query.event ? query.event.split(',') : [])
        commit('certainty', query.certainty ? query.certainty.split(',') : [])
        commit('severity', query.severity ? query.severity.split(',') : [])
        commit('urgency', query.urgency ? query.urgency.split(',') : [])
      }
    }
  },
  getters: {
    // ID
    id: state => state.id,
    // Time
    history: state => state.time.history,
    start: state => state.time.start,
    end: state => state.time.end,
    // Location
    areas: state => state.location.areas,
    land: state => state.location.land,
    latitude: state => state.location.point === null ? null : state.location.point[1],
    longitude: state => state.location.point === null ? null : state.location.point[0],
    marine: state => state.location.marine,
    point: state => state.location.point,
    regions: state => state.location.regions,
    zones: state => state.location.zones,
    // Other
    events: state => state.events,
    certainty: state => state.certainty,
    severity: state => state.severity,
    urgency: state => state.urgency,

    // Query
    queryParameters: state => {
      if (state.id) {
        return { id: state.id }
      }

      let params = {}
      if (state.time.history) {
        params.history = 1
      }
      if (state.time.start) {
        params.start = state.time.start
      }
      if (state.time.end) {
        params.end = state.time.end
      }
      if (state.location.areas.length > 0) {
        params.area = state.location.areas.join(',')
      }
      if (state.location.land) {
        params.region_type = 'land'
      }
      if (state.location.marine) {
        params.region_type = 'marine'
      }
      if (state.location.point) {
        params.point = `${state.location.point[1]},${state.location.point[0]}`
      }
      if (state.location.regions.length > 0) {
        params.region = state.location.regions.join(',')
      }
      if (state.location.zones.length > 0) {
        params.zone = state.location.zones.join(',')
      }
      if (state.events.length > 0) {
        params.event = state.events.join(',')
      }
      if (state.certainty.length > 0) {
        params.certainty = state.certainty.join(',')
      }
      if (state.severity.length > 0) {
        params.severity = state.severity.join(',')
      }
      if (state.urgency.length > 0) {
        params.urgency = state.urgency.join(',')
      }

      return params
    }
  }
}
