<template>
  <v-multiselect
    v-model="selected"
    :disabled="disabled"
    :group-label="groupLabel"
    :group-select="groupSelect"
    :group-values="groupValues"
    :hide-selected="true"
    :internal-search="false"
    :multiple="multiple"
    :options="searchOptions"
    :options-limit="limit"
    :placeholder="selectPlaceholder"
    :taggable="false"
    :track-by="optionsKey"
    @close="focused = false"
    @open="focused = true"
    @search-change="setSearch"
  >
    <template #afterList>
      <div
        v-if="searchOptionsCount > limit"
        class="multiselect__option select-more"
      >
        ...and {{ searchOptionsCount - limit - value.length }} more
      </div>
    </template>
    <template #noResult>
      No results found
    </template>
    <template #noOptions>
      No options available
    </template>
    <template #option="{ option }">
      <span v-if="option.$isLabel">{{ option.$groupLabel }}</span>
      <span v-else>{{ optionLabel(option) }}</span>
    </template>
    <template #tag="{ option, remove }">
      <span
        class="multiselect__tag multiselect__tag-custom"
        tabindex="1"
        @keypress.enter.prevent="remove(option)"
        @mousedown.prevent="remove(option)"
      >
        <span>{{ optionTag(option) }}</span>
        <i class="multiselect__tag-icon multiselect__tag-custom-icon" />
      </span>
    </template>
  </v-multiselect>
</template>

<script>
import VMultiselect from 'vue-multiselect'

const DEFAULT_LIMIT = 100

export default {
  components: {
    VMultiselect
  },
  props: {
    clearInputOnClose: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    getOptionLabel: {
      type: Function,
      default: null
    },
    getOptionTag: {
      type: Function,
      default: null
    },
    groupLabel: {
      type: String,
      default: undefined
    },
    groupSelect: {
      type: Boolean,
      default: false
    },
    groupValues: {
      type: String,
      default: undefined
    },
    limit: {
      type: Number,
      default: DEFAULT_LIMIT
    },
    multiple: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    optionsKey: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      focused: false,
      search: ''
    }
  },
  inject: {
    drawer: 'drawer'
  },
  computed: {
    flattenedOptions () {
      if (!this.groupValues) {
        return this.options
      }

      return this.options.map(function (optionGroup) {
        return optionGroup[this.groupValues]
      }.bind(this)).flat()
    },
    isDrawerOpen () {
      return this.drawer ? this.drawer.isOpen() : true
    },
    isValueSet () {
      return this.value.length > 0
    },
    selectPlaceholder () {
      return this.focused ? 'Type to explore options' : this.placeholder
    },
    searchOptions () {
      if (!this.search) {
        return this.options
      }

      const lowerCaseSearch = this.search.toLowerCase()

      if (this.groupValues) {
        return this.options.map(function (optionGroup) {
          return {
            [this.groupLabel]: optionGroup[this.groupLabel],
            [this.groupValues]: optionGroup[this.groupValues].filter(function (item) {
              return this.optionLabel(item).toLowerCase().includes(lowerCaseSearch)
            }.bind(this))
          }
        }.bind(this)).filter(function (optionGroup) {
          return optionGroup[this.groupValues].length > 0
        }.bind(this))
      }

      return this.options.filter(function (item) {
        return this.optionLabel(item).toLowerCase().includes(lowerCaseSearch)
      }.bind(this))
    },
    searchOptionsCount () {
      let optionsList = this.searchOptions

      if (this.groupValues) {
        optionsList = optionsList.map(function (optionGroup) {
          return optionGroup[this.groupValues]
        }.bind(this)).flat()
      }

      return optionsList.length
    },
    selected: {
      get () {
        return this.flattenedOptions.filter(function (option) {
          return this.value.includes(this.optionKey(option))
        }.bind(this))
      },
      set (value) {
        this.$emit('input', value.map(function (item) {
          return this.optionKey(item)
        }.bind(this)))
      }
    }
  },
  watch: {
    isDrawerOpen () {
      if (!this.isDrawerOpen && this.clearInputOnClose) {
        this.clear()
      }
    },
    value: {
      immediate: true,
      handler () {
        if (!this.isDrawerOpen && this.isValueSet) {
          this.drawer.open()
        }
      }
    }
  },
  methods: {
    clear () {
      this.$emit('input', [])
    },
    optionKey (option) {
      if (option === null || option === undefined) {
        throw new Error('Empty entry found in options list')
      }
      if (option !== Object(option)) {
        return option
      }
      if (this.optionsKey === undefined) {
        throw new Error('Option key not defined for object-based options list')
      }
      return option[this.optionsKey]
    },
    optionLabel (option) {
      if (option === null || option === undefined) {
        throw new Error('Empty entry found in options list')
      }
      if (this.getOptionLabel) {
        return this.getOptionLabel(option)
      }
      if (option !== Object(option)) {
        return option
      }
      if (option.hasOwnProperty('label')) {
        return option.label
      }
      return this.optionKey(option)
    },
    optionTag (option) {
      if (option === null || option === undefined) {
        throw new Error('Empty entry found in options list')
      }
      if (this.getOptionTag) {
        return this.getOptionTag(option)
      }
      if (option !== Object(option)) {
        return option
      }
      if (option.hasOwnProperty('tag')) {
        return option.tag
      }
      return this.optionKey(option)
    },
    setSearch (value) {
      this.search = value
    }
  }
}
</script>

<style>
.multiselect__input::placeholder {
  color: #adadad !important;
}
</style>

<style scoped>
.select-more {
  color: #adadad;
  cursor: default;
}

.multiselect__tag-custom {
  cursor: pointer;
}

.multiselect__tag-custom:focus,
.multiselect__tag-custom:hover {
  background: #369a6e;
}
</style>
