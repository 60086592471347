import retry from 'async-retry'
import NestedError from 'nested-error-stacks'

import { HTTP } from './http'
import { getLogger } from '../log'

export class ResourceLoader {
  constructor ({ backoff = 2, initialDelay = 3, retries = 3 } = {}) {
    this.__backoff = backoff
    this.__initialDelay = initialDelay
    this.__retries = retries

    this.__logger = getLogger()
  }

  async load (url, options, handler) {
    const retryOptions = {
      factor: this.__backoff,
      minTimeout: this.__initialDelay,
      onRetry: error => {
        this.__logger.error(error)
      },
      retries: this.__retries
    }

    const response = await retry(this.__retry.bind(this, url, options), retryOptions)

    return handler(response)
  }

  async __retry (url, options, abort) {
    try {
      return await HTTP.get(url, options)
    } catch (err) {
      if (!HTTP.isCancellation(err)) {
        throw err
      }
      abort(err)
    }
  }
}

export class LoadingError extends NestedError {}
