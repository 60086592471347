<template>
  <i v-if="false" />
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDrawerOpen () {
      return this.drawer.isOpen()
    },
    isValueSet () {
      return this.isDrawerOpen
    }
  },
  inject: {
    drawer: 'drawer'
  },
  watch: {
    isDrawerOpen () {
      this.$emit('input', this.isDrawerOpen)
    },
    value: {
      immediate: true,
      handler () {
        if (this.value && !this.isDrawerOpen) {
          this.drawer.open()
        } else if (!this.value && this.isDrawerOpen) {
          this.drawer.close()
        }
      }
    }
  },
  methods: {
    clear () {
      this.drawer.close()
    }
  }
}
</script>

<style scoped>
.input {
  background-color: #efefef;
  color: #333;
  height: 40px;
}

.input input {
  height: 100%;
  width: 100%;
  padding-left: 12px;
}
</style>
