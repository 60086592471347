<template>
  <div
    v-show="(multiple || selected.length === 0) && unselected.length > 0"
    class="option-control"
  >
    <select
      v-if="collapsed"
      class="option-control-select"
      @change="select($event.target.value); $event.target.value = ''"
    >
      <option
        disabled
        selected
        value=""
      >
        {{ collapsedLabel }}
      </option>
      <option
        v-for="option in unselected"
        :key="option"
        :value="option"
      >
        {{ option }}
      </option>
    </select>
    <div v-else>
      <div
        v-for="option in unselected"
        :key="option"
        class="option"
        @click="select(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    collapsedLabel: {
      type: String,
      default: 'Select an option'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    select: {
      type: Function,
      required: true
    },
    selected: {
      type: Array,
      required: true
    }
  },
  computed: {
    unselected () {
      return this.options.filter(item => !this.selected.includes(item))
    }
  }
}
</script>

<style scoped>
.option-control-select {
  background-color: #efefef;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 40px;
  width: 100%;
  border: none;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

.option {
  background-color: #efefef;
  color: #333;
  padding: 8px 8px;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}

.option:hover {
  background-color: #333;
  color: #fff;
}
</style>
