<template>
  <div class="search">
    <app-page-notice />
    <div class="search-navigation">
      <a
        href="#reset"
        @click.prevent="search"
      >
        Refresh
      </a>
      <template v-if="wizardPath.length > 1">
        <div>
          |
        </div>
        <a
          href="#return"
          @click.prevent="returnToWizard(false)"
        >
          Modify {{ lastWizardLabel }}
        </a>
      </template>
      <div>
        |
      </div>
      <a
        href="#reset"
        @click.prevent="returnToWizard(true)"
      >
        New Location
      </a>
    </div>
    <div class="search-results-details search-results-section">
      <div
        class="search-results-section-title"
        @click="toggleDrawer('details')"
      >
        <font-awesome-icon
          :class="{ rotateIconDown: drawers.details, drawerIcon: true }"
          icon="caret-right"
        />
        Search Details
      </div>
      <div
        v-if="drawers.details"
        class="search-results-section-content"
      >
        <div class="details-location">
          Location: {{ locationLabel }}
        </div>
        <div
          v-show="updated"
          class="details-updated"
        >
          Most recent: <span>{{ updated }}</span>
        </div>
        <div class="details-count">
          Alerts found: {{ alertsFound }}
        </div>
        <div
          v-show="query_.history"
          class="details-history"
        >
          Archives Enabled
        </div>
      </div>
    </div>
    <div class="search-results-atom search-results-section">
      <div
        class="search-results-section-title"
        @click="toggleDrawer('atom')"
      >
        <font-awesome-icon
          :class="{ rotateIconDown: drawers.atom, drawerIcon: true }"
          icon="caret-right"
        />
        ATOM
      </div>
      <div
        v-if="drawers.atom"
        class="search-results-section-content"
      >
        <app-atom :query="query" />
      </div>
    </div>
    <div class="search-results-refine search-results-section">
      <div
        class="search-results-section-title"
        @click="toggleDrawer('refinements')"
      >
        <font-awesome-icon
          :class="{ rotateIconDown: drawers.refinements, drawerIcon: true }"
          icon="caret-right"
        />
        Refine Results / Archives
      </div>
      <div
        v-if="drawers.refinements"
        class="search-results-section-content"
      >
        <div class="search-results-section-instructions">
          These refinements will reflect in the ATOM URL.
        </div>
        <div class="search-results-refine-option">
          <div class="search-results-refine-optionLabel">
            <font-awesome-icon
              :icon="historyIcon"
              class="search-results-refine-icon-history"
              @click="toggleHistoryEnabled"
            />
            <div>
              Enable Archives
            </div>
          </div>
          <template v-if="historyEnabled">
            <div class="search-results-section-instructions">
              This will return active and inactive alerts for the past 7 days. If start date is ommited it
              will assume from beginning, if end date is ommited it will assume until now. Both must be
              ISO8601: YYYY-MM-DDThh:mm:ssZ.
            </div>
            <div class="search-results-refine-options">
              <label class="search-results-options-history">
                <div>
                  Start
                </div>
                <div>
                  <input v-model="time.start">
                </div>
              </label>
              <label class="search-results-options-history">
                <div>
                  End
                </div>
                <div>
                  <input v-model="time.end">
                </div>
              </label>
            </div>
          </template>
        </div>
        <div class="search-results-refine-option">
          <div class="search-results-refine-optionLabel">
            Event
          </div>
          <div class="search-results-refine-options">
            <select
              v-model="events"
            >
              <option
                value="all"
              >
                All Events
              </option>
              <option
                v-for="event in eventsList"
                :key="event.id"
                :value="event.id"
              >
                {{ event.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="search-results-refine-option">
          <div class="search-results-refine-optionLabel">
            Certainty
          </div>
          <div class="search-results-refine-options">
            <label
              v-for="option in options.certainty"
              :key="option"
            >
              <input
                v-model="refinements.certainty"
                type="checkbox"
                :value="option"
              >
              {{ option }}
            </label>
          </div>
        </div>
        <div class="search-results-refine-option">
          <div class="search-results-refine-optionLabel">
            Severity
          </div>
          <div class="search-results-refine-options">
            <label
              v-for="option in options.severity"
              :key="option"
            >
              <input
                v-model="refinements.severity"
                type="checkbox"
                :value="option"
              >
              {{ option }}
            </label>
          </div>
        </div>
        <div class="search-results-refine-option">
          <div class="search-results-refine-optionLabel">
            Urgency
          </div>
          <div class="search-results-refine-options">
            <label
              v-for="option in options.urgency"
              :key="option"
            >
              <input
                v-model="refinements.urgency"
                type="checkbox"
                :value="option"
              >
              {{ option }}
            </label>
          </div>
        </div>
        <template-button
          event-value="refine"
          type="primary"
          class="search-results-refine-button"
          @button:click="refine"
        >
          Refine
        </template-button>
      </div>
    </div>
    <app-alerts
      :loader="alertsLoader"
      :lookup-by-id="Boolean(query.id)"
      :zones="zonesByCriteria"
      class="search-results"
    />
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { faCaretRight, faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { mapGetters, mapMutations } from 'vuex'
import { LAND_AREAS, MARINE_AREAS, MARINE_REGIONS, CERTAINTY, SEVERITY, URGENCY } from '../../constants'

import AppPageNotice from '../PageNotice.vue'
import AppAlerts from '../Alerts.vue'
import AppAtom from '../Atom.vue'

library.add(faCaretRight, faToggleOn, faToggleOff)

const QUERY_LIMIT = 50

export default {
  components: {
    AppPageNotice,
    AppAlerts,
    AppAtom,
    FontAwesomeIcon
  },
  props: {
    query: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      drawers: {
        details: true,
        atom: false,
        refinements: false
      },
      eventsLoader: this.$api.alerts.events(),
      historyEnabled: false,
      time: {
        start: null,
        end: null
      },
      events: 'all',
      refinements: {
        certainty: [],
        severity: [],
        urgency: []
      },
      options: {
        certainty: CERTAINTY,
        severity: SEVERITY,
        urgency: URGENCY
      },
      query_: this.query
    }
  },
  computed: {
    ...mapGetters([
      'wizardPath'
    ]),
    ...mapGetters('criteria', [
      'queryParameters'
    ]),
    atomAnchorTimestamp () {
      const date = new Date()
      return date.getMilliseconds()
    },
    alertsFound () {
      if (this.alertsReady) {
        let found = this.alertsLoader.result.length
        if (this.query_.history) {
          found += ' (More available if requested)'
        }
        return found
      } else {
        return '--'
      }
    },
    alertsLoader () {
      // Trigger to force reload of data
      // eslint-disable-next-line no-unused-vars
      const dummy = this.query_

      if (this.query_.id) {
        return this.$api.alerts.get(this.query_.id)
      }
      if (this.query_.history) {
        return this.$api.alerts.search({ ...this.alertsParameters, limit: QUERY_LIMIT })
      }

      return this.$api.alerts.active(this.alertsParameters)
    },
    alertsParameters () {
      if (this.query_.history) {
        const { history, ...query } = this.query_
        return query
      }
      return this.query_
    },
    alertsReady () {
      return this.alertsLoader && this.alertsLoader.result && !this.alertsLoader.loading
    },
    eventsLabel () {
      if (this.eventsLoader === null) {
        return 'Loading events...'
      }
      if (this.eventsLoader.error) {
        return 'Error loading events'
      }
      if (this.eventsLoader.loading) {
        return 'Loading events...'
      }
      return 'Select event(s)'
    },
    eventsList () {
      return this.eventsLoader.result.map(item => {
        return {
          id: item,
          label: item
        }
      })
    },
    eventsReady () {
      return this.eventsLoader !== null && !this.eventsLoader.error && !this.eventsLoader.loading
    },
    historyIcon () {
      return this.historyEnabled ? 'toggle-on' : 'toggle-off'
    },
    lastWizardLabel () {
      return this.wizardPath[this.wizardPath.length - 1].label
    },
    location () {
      return {
        areas: this.query_.area ? this.query_.area.split(',') : [],
        land: this.query_.region_type === 'land',
        marine: this.query_.region_type === 'marine',
        regions: this.query_.region ? this.query_.region.split(',') : [],
        zones: this.query_.zone ? this.query_.zone.split(',') : []
      }
    },
    locationLabel () {
      if (this.query_.region_type === 'land') {
        return 'All land'
      } else if (this.query_.region_type === 'marine') {
        return 'All marine'
      } else if (this.query_.area) {
        if (this.query_.area in LAND_AREAS) {
          return `Land area ${LAND_AREAS[this.query_.area]}`
        }
        if (this.query_.area in MARINE_AREAS) {
          return `Marine area ${MARINE_AREAS[this.query_.area]}`
        }
        return 'Unknown area'
      } else if (this.query_.region) {
        if (this.query_.region in MARINE_REGIONS) {
          return `Marine region ${MARINE_REGIONS[this.query_.region]}`
        }
        return 'Unknown region'
      } else if (this.query_.zone) {
        const zone = this.$store.getters.zones.find(zone => {
          return zone.id === this.query_.zone
        })
        if (zone) {
          return `Zone ${zone.id} ${zone.name}`
        }
        return 'Unknown zone'
      } else if (this.query_.point) {
        return `Point for ${this.query_.point}`
      } else {
        return 'All locations'
      }
    },
    updated () {
      if (this.alertsReady && this.alertsLoader.attributes.hasOwnProperty('updated')) {
        if (DateTime.local().minus({ hours: 24 }) < this.alertsLoader.attributes.updated) {
          const updatedTimeShort = this.alertsLoader.attributes.updated.toLocal().toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
          return updatedTimeShort
        } else {
          return 'over 24 hours ago'
        }
      }
      return '--'
    },
    zones () {
      return this.$store.getters.zones.reduce((map, zone) => {
        map[zone.id] = zone
        return map
      }, {})
    },
    zonesByCriteria () {
      return Object.values(this.zones).reduce((map, zone) => {
        if (this.location.land && !['county', 'fire', 'public'].includes(zone.type)) {
          return map
        }
        if (this.location.marine && !['coastal', 'offshore'].includes(zone.type)) {
          return map
        }
        if (this.location.areas.length > 0 && !this.location.areas.includes(zone.id.substring(0, 2))) {
          return map
        }
        // Regions unfiltered for now
        if (this.location.zones.length > 0 && !this.location.zones.includes(zone.id)) {
          return map
        }
        map[zone.id] = zone
        return map
      }, {})
    }
  },
  watch: {
    async alertsLoader () {
      this.$store.commit('alertsLoader', this.alertsLoader)
      await this.alertsLoader.load()
    },
    query: {
      immediate: true,
      handler () {
        this.query_ = Object.assign({}, this.query)
      }
    }
  },
  beforeMount () {
    this.$store.dispatch('criteria/setToQuery', this.query)
    this.refinements.certainty = this.$store.getters['criteria/certainty']
    this.refinements.severity = this.$store.getters['criteria/severity']
    this.refinements.urgency = this.$store.getters['criteria/urgency']
    this.historyEnabled = this.$store.getters['criteria/history']
    if (this.historyEnabled) {
      this.time.start = this.$store.getters['criteria/start']
      this.time.end = this.$store.getters['criteria/end']
    }
    this.events = this.$store.getters['criteria/events'].length === 1 ? this.$store.getters['criteria/events'][0] : 'all'
  },
  async mounted () {
    await this.eventsLoader.load()
  },
  methods: {
    ...mapMutations('criteria', {
      'resetCriteria': 'reset',
      'setCertainty': 'certainty',
      'setSeverity': 'severity',
      'setUrgency': 'urgency',
      'setHistory': 'history',
      'setStart': 'start',
      'setEnd': 'end',
      'setEvents': 'events'
    }),
    criteriaChanged () {
      const routeQuery = this.$route.query
      const criteriaQuery = this.queryParameters

      return Object.entries(criteriaQuery).sort().toString() !== Object.entries(routeQuery).sort().toString()
    },
    refine () {
      for (let refinement in this.refinements) {
        if (this.refinements[refinement]) {
          if (this.refinements[refinement].length === 0) {
            this.resetCriteria(refinement)
          } else {
            switch (refinement) {
              case 'certainty':
                this.setCertainty(this.refinements.certainty)
                break
              case 'severity':
                this.setSeverity(this.refinements.severity)
                break
              case 'urgency':
                this.setUrgency(this.refinements.urgency)
            }
          }
        }
      }
      this.resetCriteria('time')
      if (this.historyEnabled) {
        this.setHistory(true)
        if (DateTime.fromISO(this.time.start)) {
          this.setStart(this.time.start)
        }
        if (DateTime.fromISO(this.time.end)) {
          this.setEnd(this.time.end)
        }
      }
      this.resetCriteria('events')
      if (this.events !== 'all') {
        this.setEvents([this.events])
      }
      this.search()
    },
    returnToWizard (reset) {
      let query = {}
      if (reset) {
        query = {
          reset: true
        }
      }
      this.$router.push({
        path: '/',
        query: query
      })
    },
    async search () {
      if (this.criteriaChanged()) {
        await this.$router.push({
          path: '/search',
          query: this.queryParameters
        })
      } else {
        this.query_ = Object.assign({}, this.query_)
      }
    },
    toggleDrawer (id) {
      this.drawers[id] = !this.drawers[id]
    },
    toggleHistoryEnabled () {
      this.historyEnabled = !this.historyEnabled
    }
  }
}
</script>

<style scoped>
.search-navigation {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}

.search-navigation > a {
  display: block;
}

.search-results {
  margin-top: 20px;
  margin-bottom: 50px;
}

.details-updated > span {
  white-space: nowrap;
}

.search-results-section {
  border-left: 5px solid #999;
  background: #efefef;
  margin-bottom: 10px;
}

.search-results-section-title {
  padding: 10px 10px;
  font-size: 106%;
  font-weight: bold;
  cursor: pointer;
}

.search-results-section-content {
  padding: 4px 10px 10px 20px;
}

.drawerIcon {
  transition: 0.5s;
  font-size: 20px;
  vertical-align: text-bottom;
  margin-right: 3px;
}

.rotateIconDown {
  transform: rotate(90deg);
}

.search-results-section-instructions {
  margin-bottom: 10px;
}

.search-results-refine-option {
  margin-bottom: 26px;
}

.search-results-refine-optionLabel {
  font-weight: bold;
  display: flex;
  gap: 10px;
  align-items: center;
}

.search-results-refine-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin-top: 5px;
}

.search-results-options-history {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  align-items: center;
}

.search-results-options-history > div:first-child {
  width: 40px;
}

.search-results-refine-icon-history {
  font-size: 30px;
  cursor: pointer;
}

.search-results-refine-options > label {
  white-space: nowrap;
}

.search-results-refine-options > label input {
  font-size: 16px;
  padding: 5px;
}

.search-results-refine-options > label input[type=checkbox] {
  transform: scale(1.5);
}

.search-results-refine-options > select {
  font-size: 14px;
  padding: 10px 2px;
}

.search-results-refine-button {
  margin-top: 20px;
}
</style>
