<template>
  <div>
    <app-wizard>
      <template v-slot:title>
        Zones
      </template>
      <template v-slot:description>
        Please select a zone to view alerts.
      </template>
      <template v-slot:options>
        <div class="filter">
          <div class="filterLabel">
            Filter Zones
          </div>
          <div class="filterInput">
            <input
              ref="filter"
              v-model="filter"
            >
          </div>
        </div>
        <div
          v-if="lists && lists.length > 0"
          class="type"
        >
          Zone Type
          <label
            v-for="list in zonesList"
            :key="list.label"
          >
            <input
              v-model="type_"
              type="radio"
              name="type"
              :value="list.id"
            >
            {{ list.label }}
          </label>
        </div>
        <div class="order">
          <div>
            Order by
          </div>
          <select
            v-model="order"
            name="order"
          >
            <option value="id">
              Id
            </option>
            <option value="label">
              Name
            </option>
          </select>
          <select
            v-model="orderDirection"
            name="orderDirection"
          >
            <option value="ASC">
              ASC
            </option>
            <option value="DESC">
              DESC
            </option>
          </select>
        </div>
        <ul class="optionsList optionsPrimaryList">
          <li>
            <div class="optionsListLabel">
              {{ zonesLabel }}
            </div>
            <app-wizard-option
              v-for="zone in filteredZoneList"
              :id="zone.id"
              :key="zone.id"
              :expand="'option1'"
              :opened-drawer="openedDrawer"
            >
              <template #option1>
                <a
                  :href="`#${zone.id}`"
                  @click.prevent="loadZone(zone.id)"
                >
                  {{ zone.id }}
                </a>
              </template>
              <template #option2>
                <a
                  :href="`#${zone.id}-atom`"
                  @click.prevent="openDrawer(zone.id, 'atom')"
                >
                  ATOM
                </a>
              </template>
              <template #description>
                {{ zone.label }}
              </template>
              <template #drawer-atom>
                <app-atom
                  owner="location"
                  :query="{zone: zone.id}"
                />
              </template>
            </app-wizard-option>
            <div
              v-if="zonesReady && filteredZoneList.length === 0"
              class="emptyset"
            >
              <!-- eslint-disable-next-line vue/max-attributes-per-line -->
              None found (<a href="#clearfilter" @click.prevent="resetFilter">Clear Filter</a>)
            </div>
          </li>
        </ul>
      </template>
    </app-wizard>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { LAND_AREAS, MARINE_AREAS, MARINE_AREAS_BY_REGION } from '../../constants'

import AppWizard from './Wizard.vue'
import AppWizardOption from './WizardOption.vue'
import AppAtom from '../Atom.vue'

export default {
  components: {
    AppWizard,
    AppWizardOption,
    AppAtom
  },
  props: {
    lists: {
      type: [Array],
      default: null
    },
    type: {
      type: [String],
      default: 'public'
    },
    unit: {
      type: [String],
      required: true
    },
    unitCode: {
      type: [String],
      required: true
    },
    unitLabel: {
      type: [String],
      required: true
    },
    unitType: {
      type: [String],
      required: true
    }
  },
  data () {
    return {
      filter: null,
      type_: 'public',
      openedDrawer: null,
      order: 'id',
      orderDirection: 'ASC'
    }
  },
  computed: {
    ...mapGetters([
      'wizardOptions'
    ]),
    zonesLabel () {
      if (this.zonesLoader === null) {
        return 'Loading zones...'
      }
      if (this.zonesLoader.error) {
        return 'Error loading zones'
      }
      if (this.zonesLoader.loading) {
        return 'Loading zones...'
      }
      const typeLabel = this.type_ === 'public' ? 'Public' : 'County'
      return `${typeLabel} Zones for ${this.unitLabel}`
    },
    zonesList () {
      let list = {}
      list.public = {
        id: 'public',
        label: 'Public',
        zones: this.$store.getters.zones.filter(zone => {
          const code = zone.id.slice(0, 2)
          if (this.unitType === 'marine' && zone.isMarineZone()) {
            if (this.unit === 'region') {
              if (MARINE_AREAS_BY_REGION[this.unitCode].includes(code)) {
                return true
              }
            } else {
              if (code === this.unitCode && code in MARINE_AREAS) {
                return true
              }
            }
          } else if (this.unitType === 'land') {
            if (code === this.unitCode && zone.type === 'public' && code in LAND_AREAS) {
              return true
            }
          }
        }).map(zone => {
          const code = zone.id.slice(0, 2)
          return {
            id: zone.id,
            label: `${zone.name}, ${code}`
          }
        })
      }

      if (this.lists) {
        if (this.lists.includes('county')) {
          list.county = {
            id: 'county',
            label: 'County',
            zones: this.$store.getters.zones.filter(zone => {
              const code = zone.id.slice(0, 2)
              if (code === this.unitCode && zone.type === 'county' && code in LAND_AREAS) {
                return true
              }
            }).map(zone => {
              const code = zone.id.slice(0, 2)
              const suffix = code === 'LA' ? 'Parish' : 'County'
              return {
                id: zone.id,
                label: `${zone.name} ${suffix}, ${code}`
              }
            })
          }
        }
      }

      return list
    },
    filteredZoneList () {
      let zones = this.type_ === 'county' ? this.zonesList.county.zones : this.zonesList.public.zones

      if (this.filter !== null && this.filter.trim().length > 0) {
        zones = zones.filter(item => {
          const lcaseFilter = this.filter.trim().toLowerCase()
          const lcaseId = item.id.toLowerCase()
          const lcaseLabel = item.label.toLowerCase()
          return lcaseId.indexOf(lcaseFilter) >= 0 || lcaseLabel.indexOf(lcaseFilter) >= 0
        })
      }

      const sortDir = this.orderDirection === 'DESC' ? -1 : 1
      return zones.sort((a, b) => sortDir * a[this.order].localeCompare(b[this.order]))
    },
    zonesLoader () {
      return this.$store.getters.zonesLoader
    },
    zonesReady () {
      return this.zonesLoader !== null && !this.zonesLoader.error && !this.zonesLoader.loading
    }
  },
  watch: {
    type: {
      immediate: true,
      handler () {
        this.type_ = this.type
      }
    }
  },
  beforeMount () {
    this.addWizardPath({
      label: 'Zone',
      wizard: 'zone'
    })
  },
  mounted () {
    this.focusFilter()
  },
  methods: {
    ...mapMutations([
      'addWizardPath',
      'resetWizardPath'
    ]),
    ...mapMutations('criteria', {
      setZones: 'zones',
      resetAllParams: 'reset'
    }),
    focusFilter () {
      this.$refs.filter.focus()
    },
    loadZone (id) {
      this.resetAllParams()
      this.setZones([id])
      this.$emit('search')
    },
    resetFilter () {
      this.filter = null
    },
    openDrawer (optionId, drawerId) {
      this.openedDrawer = {
        id: optionId,
        drawer: drawerId
      }
    }
  }
}
</script>

<style scoped>
.optionsList {
  margin-left: 0;
  list-style-type: none;
  padding-left: 20px;
}

.optionsListLabel {
  font-size: 100%;
}

.optionsList .landZonesOptions {
  display: flex;
  gap: 10px;
}

.optionsList .landZonesOption {
  display: none;
}

@media (min-width: 520px) {
  .optionsList .landZonesDefault {
    display: none;
  }

  .optionsList .landZonesOption {
    display: initial;
  }
}

.optionsPrimaryList {
  padding-left: 0;
}

.emptyset {
  font-style: italic;
}

.filter {
  max-width: 300%;
}

.filter input {
  height: 40px;
  font-size: 102%;
  padding: 10px;
}

.type {
  margin-top: 10px;
}

.order {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.order > select {
  display: block;
  font-size: 16px;
}
</style>
