<template>
  <div class="input">
    <vue-numeric-input
      v-model="inputValue"
      controls-type="updown"
      :maximum="maximum"
      :minimum="minimum"
      :placeholder="placeholder"
      :precision="precision"
      :step="step"
    />
  </div>
</template>

<script>
export default {
  props: {
    clearInputOnClose: {
      type: Boolean,
      default: true
    },
    maximum: {
      type: Number,
      default: null
    },
    minimum: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Enter value'
    },
    precision: {
      type: Number,
      default: 2
    },
    step: {
      type: Number,
      default: 1
    },
    value: {
      type: Number,
      default: null
    }
  },
  computed: {
    inputValue: {
      get () { return this.value !== null ? this.value : undefined },
      set (value) { this.$emit('input', value) }
    },
    isDrawerOpen () {
      return this.drawer ? this.drawer.isOpen() : true
    }
  },
  inject: {
    drawer: 'drawer'
  },
  watch: {
    isDrawerOpen () {
      if (!this.isDrawerOpen && this.clearInputOnClose) {
        this.clear()
      }
    },
    isValueSet () {
      return this.value !== null
    },
    value: {
      immediate: true,
      handler () {
        if (!this.isDrawerOpen && this.isValueSet) {
          this.drawer.open()
        }
      }
    }
  },
  methods: {
    clear () {
      this.$emit('input', null)
    }
  }
}
</script>

<style scoped>
.input {
  background-color: #efefef;
  color: #333;
  height: 40px;
}

.input input {
  height: 100%;
  width: 100%;
  padding-left: 12px;
}
</style>
