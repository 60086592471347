export class Zone {
  constructor ({ cwa, id, name, state, type }) {
    this.__cwa = cwa
    this.__id = id
    this.__name = name
    this.__state = state
    this.__type = type
  }

  get cwa () {
    return this.__cwa
  }

  get id () {
    return this.__id
  }

  get name () {
    return this.__name
  }

  get state () {
    return this.__state
  }

  get type () {
    return this.__type
  }

  isForecastZone () {
    return ['coastal', 'offshore', 'public'].includes(this.__type)
  }

  isLandZone () {
    return ['county', 'fire', 'public'].includes(this.__type)
  }

  isMarineZone () {
    return ['coastal', 'offshore'].includes(this.__type)
  }
}
