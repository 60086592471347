<template>
  <div class="location">
    <app-page-notice />
    <div class="searchById">
      <a
        href="#searchbyid"
        @click.prevent="gotoId"
      >
        Search for a specific alert id
      </a>
    </div>
    <app-wizards :query="query" />
  </div>
</template>

<script>
import AppPageNotice from '../PageNotice.vue'
import AppWizards from '../wizards/Wizards.vue'

export default {
  components: {
    AppPageNotice,
    AppWizards
  },
  props: {
    query: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    async gotoId () {
      await this.$router.push({
        path: '/id'
      })
    }
  }
}
</script>

<style scoped>
.searchById {
  margin-bottom: 10px;
}
</style>
