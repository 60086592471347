<template>
  <div class="option">
    <div class="optionActions">
      <div
        v-for="option in options"
        :key="option[0]"
        :class="optionActionClass(option[0])"
      >
        <slot :name="option[0]" />
      </div>
    </div>
    <div
      v-if="description"
      class="description"
    >
      <slot name="description" />
    </div>
    <div
      v-for="drawer in drawers"
      v-show="openedDrawerId === drawer[0]"
      :key="drawer[0]"
      class="drawer"
    >
      <slot :name="drawer[0]" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String],
      required: true
    },
    expand: {
      type: [String, Array],
      default: null
    },
    alignRight: {
      type: [String, Array],
      default: null
    },
    openedDrawer: {
      type: [Object],
      default: null
    }
  },
  data () {
    return {
      openedDrawerId: null
    }
  },
  computed: {
    description () {
      return !!this.$slots.description
    },
    expandOptionActions () {
      if (this.expand === null) {
        return []
      }
      return Array.isArray(this.expand) ? this.expand : [this.expand]
    },
    alignRightOptionActions () {
      if (this.alignRight === null) {
        return []
      }
      return Array.isArray(this.alignRight) ? this.alignRight : [this.alignRight]
    },
    drawers () {
      return Object.entries(this.$slots).filter(slot => {
        if (slot[0].indexOf('drawer') === 0) {
          return slot
        }
      })
    },
    options () {
      return Object.entries(this.$slots).filter(slot => {
        if (slot[0].indexOf('option') === 0) {
          return slot
        }
      })
    }
  },
  watch: {
    openedDrawer () {
      if (this.openedDrawer !== null && this.openedDrawer.id && this.openedDrawer.id.indexOf(this.id) === 0) {
        const openedDrawer = `drawer-${this.openedDrawer.drawer}`
        if (this.openedDrawerId !== openedDrawer) {
          this.openedDrawerId = openedDrawer
          return
        }
      }
      this.openedDrawerId = null
    }
  },
  methods: {
    optionActionClass (optionName) {
      return {
        'align-right': this.alignRightOptionActions.find(option => option === optionName),
        expand: this.expandOptionActions.find(option => option === optionName),
        optionAction: true
      }
    }
  }
}
</script>

<style scoped>
.option {
  padding: 0 6px 6px;
}

.optionActions {
  display: flex;
  gap: 10px;
}

.option:nth-child(even) {
  background-color: rgb(245, 252, 255);
  border-radius: 5px;
}

.optionAction {
  white-space: nowrap;
  line-height: 15px;
  padding: 15px 0 4px;
}

.description {
  margin-top: 4px;
  line-height: 22px;
}

.expand {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}

.align-right {
  text-align: right;
}

.drawer {
  margin-top: 5px;
  padding: 10px 10px 10px;
}
</style>
