<template>
  <div class="input">
    <input
      v-model="inputValue"
      :placeholder="placeholder"
      type="text"
    >
  </div>
</template>

<script>
export default {
  props: {
    clearInputOnClose: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    inputValue: {
      get () { return this.value || '' },
      set (value) { this.$emit('input', value || null) }
    },
    isDrawerOpen () {
      return this.drawer ? this.drawer.isOpen() : true
    },
    isValueSet () {
      return this.value !== null
    }
  },
  inject: {
    drawer: 'drawer'
  },
  watch: {
    isDrawerOpen () {
      if (!this.isDrawerOpen && this.clearInputOnClose) {
        this.clear()
      }
    },
    value: {
      immediate: true,
      handler () {
        if (!this.isDrawerOpen && this.isValueSet) {
          this.drawer.open()
        }
      }
    }
  },
  methods: {
    clear () {
      this.$emit('input', null)
    }
  }
}
</script>

<style scoped>
.input {
  background-color: #efefef;
  color: #333;
  height: 40px;
}

.input input {
  height: 100%;
  width: 100%;
  padding-left: 12px;
}
</style>
