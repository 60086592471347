import { DateTime } from 'luxon'

import { Alert } from '../entities'
import { ApiLoader, ApiCollectionLoader, ApiTransformationResult } from '../loaders'
import { getLogger } from '../../log'

export class ApiAlerts {
  constructor (baseUrl) {
    this.__baseUrl = baseUrl
    this.__logger = getLogger()
  }

  active (parameters) {
    let url = new URL(`${this.__baseUrl}/alerts/active`)

    if (parameters) {
      for (const [name, value] of Object.entries(parameters)) {
        url.searchParams.set(name, value)
      }
    }

    return new ApiCollectionLoader(url.href, this.__transformAlerts.bind(this))
  }

  events () {
    const url = new URL(`${this.__baseUrl}/alerts/types`)

    return new ApiLoader(url.href, response => new ApiTransformationResult(response.data.eventTypes), {}, [])
  }

  get (id) {
    const url = new URL(`${this.__baseUrl}/alerts/${id}`)

    return new ApiLoader(url.href, this.__transformAlert.bind(this))
  }

  search (parameters) {
    let url = new URL(`${this.__baseUrl}/alerts`)

    if (parameters) {
      for (const [name, value] of Object.entries(parameters)) {
        url.searchParams.set(name, value)
      }
    }

    return new ApiCollectionLoader(url.href, this.__transformAlerts.bind(this))
  }

  __createAlertFromProperties (properties) {
    let vtec = null
    if (properties.parameters && properties.parameters.VTEC && properties.parameters.VTEC.length > 0) {
      vtec = properties.parameters.VTEC[0]
    }

    return new Alert({
      id: properties.id,
      affectedZones: properties.affectedZones,
      areaDesc: properties.areaDesc,
      certainty: properties.certainty,
      description: properties.description,
      ends: properties.ends ? DateTime.fromISO(properties.ends, { zone: 'UTC' }) : null,
      event: properties.event,
      expiration: DateTime.fromISO(properties.ends ? properties.ends : properties.expires, { zone: 'UTC' }),
      headline: properties.headline,
      instruction: properties.instruction,
      issuance: DateTime.fromISO(properties.effective, { zone: 'UTC' }),
      references: properties.references,
      sender: properties.sender,
      senderName: properties.senderName,
      severity: properties.severity,
      status: properties.status,
      urgency: properties.urgency,
      url: properties['@id'],
      vtec: vtec
    })
  }

  __transformAlert (response) {
    const entry = this.__createAlertFromProperties(response.data)
    this.__logger.debug(`Found details for ${entry.event} alert ${entry.id}`, entry)

    return new ApiTransformationResult(entry)
  }

  __transformAlerts (response) {
    const entries = response.data['@graph'].map(this.__createAlertFromProperties)
    this.__logger.debug(`Fetched ${Object.keys(entries).length} alerts`)

    return new ApiTransformationResult(entries, {
      updated: DateTime.fromISO(response.data.updated, { zone: 'UTC' })
    })
  }
}
