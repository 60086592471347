import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
import localforage from 'localforage'

import { getLogger } from '../log'

const storage = localforage.createInstance({ name: 'http-cache' })
const cache = setupCache({
  readHeaders: true,
  maxAge: 1 * 60 * 1000, // 1 minute default
  store: storage
})

const client = axios.create({
  adapter: cache.adapter
})

const logger = getLogger()

export const HTTP = {
  createCancellationToken: () => {
    const source = axios.CancelToken.source()
    source.token.cancel = function () {
      logger.warning('Cancelling request')
      source.cancel()
    }
    source.token.isCancelled = function () {
      return Boolean(source.token.reason)
    }
    return source.token
  },
  isCancellation: (error) => axios.isCancel(error),
  async delete (...args) {
    logger.debug('Sending DELETE request', args)
    try {
      const response = await client.delete(...args)
      logger.debug('Received DELETE response', args, response)
      return response
    } catch (err) {
      logger.error('DELETE request failed', args, err)
      throw err
    }
  },
  async get (...args) {
    logger.debug('Sending GET request', args)
    try {
      const response = await client.get(...args)
      logger.debug('Received GET response', args, response)
      return response
    } catch (err) {
      logger.error('GET request failed', args, err)
      throw err
    }
  },
  async head (...args) {
    logger.debug('Sending HEAD request', args)
    try {
      const response = await client.head(...args)
      logger.debug('Received HEAD response', args, response)
      return response
    } catch (err) {
      logger.error('HEAD request failed', args, err)
      throw err
    }
  },
  async post (...args) {
    logger.debug('Sending POST request', args)
    try {
      const response = await client.post(...args)
      logger.debug('Received POST response', args, response)
      return response
    } catch (err) {
      logger.error('POST request failed', args, err)
      throw err
    }
  },
  async put (...args) {
    logger.debug('Sending PUT request', args)
    try {
      const response = await client.put(...args)
      logger.debug('Received PUT response', args, response)
      return response
    } catch (err) {
      logger.error('PUT request failed', args, err)
      throw err
    }
  }
}
