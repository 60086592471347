export const LAND_AREAS = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AS': 'American Samoa',
  'AR': 'Arkansas',
  'AZ': 'Arizona',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District of Columbia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'GU': 'Guam',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VI': 'Virgin Islands',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
}

export const LAND_REGIONS = {
  'AR': 'Alaska Region',
  'CR': 'Central Region',
  'ER': 'Eastern Region',
  'PR': 'Pacific Region',
  'SR': 'Southern Region',
  'WR': 'Western Region'
}

/**
 * Derived from directive 10-302
 *
 * http://www.nws.noaa.gov/directives/sym/pd01003002curr.pdf
 * Coastal and Offshore Marine Codes Listings for Emergency Alert System (EAS)
 * and NOAA Weather Radio (NWR) Applications, Page 97
 */
export const MARINE_AREAS = {
  'PZ': 'Eastern N. Pacific Ocean',
  'PK': 'N. Pacific Ocean Near Alaska',
  'PH': 'Central Pacific Ocean',
  'PS': 'S. Central Pacific Ocean',
  'PM': 'Western Pacific Ocean',
  'AN': 'Northwest N. Atlantic Ocean',
  'AM': 'West N. Atlantic Ocean',
  'GM': 'Gulf of Mexico',
  'LS': 'Lake Superior',
  'LM': 'Lake Michigan',
  'LH': 'Lake Huron',
  'LC': 'Lake St. Clair',
  'LE': 'Lake Erie',
  'LO': 'Lake Ontario'
}

export const MARINE_REGIONS = {
  'AL': 'Alaska',
  'AT': 'Atlantic',
  'GL': 'Great Lakes',
  'GM': 'Gulf of Mexico',
  'PA': 'Pacific',
  'PI': 'Pacific Islands'
}

export const MARINE_AREAS_BY_REGION = {
  'AL': ['PK'],
  'AT': ['AM', 'AN'],
  'GL': ['LC', 'LE', 'LH', 'LM', 'LO', 'LS', 'SL'],
  'GM': ['GM'],
  'PA': ['PZ'],
  'PI': ['PH', 'PM', 'PS']
}

export const CERTAINTY = [
  'Unknown',
  'Unlikely',
  'Possible',
  'Likely',
  'Observed'
]

export const SEVERITY = [
  'Unknown',
  'Minor',
  'Moderate',
  'Severe',
  'Extreme'
]

export const URGENCY = [
  'Unknown',
  'Past',
  'Future',
  'Expected',
  'Immediate'
]
