<template>
  <div
    v-if="wizardPathWithoutCurrentWizard.length > 0"
    class="container"
  >
    <div class="pathText">
      Return to
    </div>
    <div
      v-for="(path, count) in wizardPathWithoutCurrentWizard"
      :key="`pathLabel${count}`"
      class="pathWizard"
    >
      <div
        v-if="count !== 0"
        class="pathWizardPrefix"
      >
        &gt;
      </div>
      <a
        href="`#wizard${path.wizard}`"
        class="pathLink"
        @click.prevent="gotoWizard(path.wizard)"
      >
        {{ path.label }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'wizard',
      'wizardPath'
    ]),
    wizardPathWithoutCurrentWizard () {
      return this.wizardPath.filter(path => {
        return path.wizard !== this.wizard
      })
    }
  },
  watch: {
  },
  methods: {
    ...mapMutations([
      'gotoWizard'
    ])
  }
}
</script>

<style scoped>
.container {
  padding: 10px 20px;
  font-size: 98%;
  border-bottom: 1px solid #efefef;
  display: flex;
}

.pathWizard {
  display: flex;
}

.pathLink {
  padding-left: 5px;
}

.pathWizardPrefix {
  padding-left: 5px;
}
</style>
