import deepmerge from 'deepmerge'
import VueNumericInput from 'vue-numeric-input'

import * as components from './components'
import { getLogger } from '../log'
import { SchemaValidator } from '../schema'

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

const COMPONENTS = {
  'edit-control': components.EditControl,
  'input-drawer': components.InputDrawer,
  'input-number': components.InputNumber,
  'input-point': components.InputPoint,
  'input-select': components.InputSelect,
  'input-text': components.InputText,
  'input-time': components.InputTime,
  'option-control': components.OptionControl
}

const DEFAULTS = {
  componentPrefix: 'criteria-'
}

const CONFIG_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  $id: '/config',
  type: 'object',
  additionalProperties: false,
  required: ['componentPrefix'],
  properties: {
    componentPrefix: { type: 'string' }
  }
}

function plugin (Vue, options = {}) {
  options = deepmerge(DEFAULTS, options)

  const validator = new SchemaValidator({ schemas: [CONFIG_SCHEMA] })
  validator.validate('/config', options)

  Vue.use(VueNumericInput)

  for (const key of Object.keys(COMPONENTS)) {
    // eslint-disable-next-line security/detect-object-injection
    Vue.component(`${options.componentPrefix}${key}`, COMPONENTS[key])
  }

  getLogger().debug(
    'Criteria plugin initialized:' +
    `\n  Component prefix: ${options.componentPrefix}`
  )
}

export default plugin

export {
  components,
  DEFAULTS
}
