<template>
  <div :class="{ input: true, 'edit-mode': editMode }">
    <slot
      v-if="!$editMode"
      name="display"
    >
      <input
        class="input-display"
        readonly
        :placeholder="placeholder"
        :value="displayValue"
        @click="$editMode = true"
      >
    </slot>
    <template v-else>
      <div class="editor">
        <div
          v-if="$slots.instructions"
          class="instructions"
        >
          <slot name="instructions" />
        </div>
        <slot name="edit">
          No options provided.
        </slot>
        <div class="actionbar">
          <div
            :class="{ 'actionbar-action actionbar-action-confirm': true, 'actionbar-action-confirm-disabled': !inputValid }"
            @click="confirm"
          >
            Confirm
          </div>
          <div
            class="actionbar-action actionbar-action-cancel"
            @click="cancel"
          >
            Cancel
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    displayValue: {
      type: String,
      default: null
    },
    editMode: {
      type: Boolean,
      default: false,
      fallback: true
    },
    inputValid: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
      this.$editMode = false
    },
    confirm () {
      if (this.inputValid) {
        this.$emit('confirm')
        this.$editMode = false
      }
    }
  }
}
</script>

<style scoped>
.input {
  background-color: #efefef;
  color: #333;
  height: 40px;
}

.edit-mode {
  height: initial;
}

.input-display {
  cursor: pointer;
}

.input input {
  height: 40px;
  background-color: #fff;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  position: relative;
  width: 100%;
  padding-left: 12px;
  padding-right: 44px;
  font-weight: 400;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 14px;
  z-index: 0;
}

.editor {
  background: #efefef;
}

.actionbar {
  display: flex;
  background: #dfdfdf;
  height: 30px;
  align-items: center;
  border-top: 1px solid #aaa;
  margin-top: 10px;
}

.actionbar-action {
  flex: 0 0;
  width: initial;
  padding: 0 18px;
  color: #333;
  border-right: 1px solid #aaa;
  align-self: stretch;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.actionbar-action-confirm {
  background: #555;
  color: #fff;
  font-weight: bold;
  border-right: none;
}

.actionbar-action-confirm-disabled {
  color: #777;
  cursor: default;
}

.actionbar-action-cancel {
  color: #777;
}

.instructions {
  padding: 4px;
  font-size: 85%;
}
</style>
