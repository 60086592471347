<template>
  <div class="container">
    <app-wizard-location
      v-if="wizard == 'location'"
      @goto="goto"
      @search="search"
    />
    <app-wizard-region-area
      v-if="wizard == 'land'"
      :area-options="land.areas"
      :region-options="land.regions"
      :quick-zones="['public', 'county']"
      type="land"
      @goto="goto"
      @search="search"
    />
    <app-wizard-region-area
      v-if="wizard == 'marine'"
      :area-options="marine.areas"
      :region-options="marine.regions"
      type="marine"
      @goto="goto"
      @search="search"
    />
    <app-wizard-zone
      v-if="wizard == 'zone'"
      :lists="options.zone.lists"
      :type="options.zone.type"
      :unit="options.zone.unit.unit"
      :unit-code="options.zone.unit.code"
      :unit-label="options.zone.unit.label"
      :unit-type="options.zone.unit.type"
      @search="search"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { LAND_AREAS, MARINE_AREAS, MARINE_REGIONS } from '../../constants'

import AppWizardLocation from './Location.vue'
import AppWizardZone from './Zone.vue'
import AppWizardRegionArea from './RegionArea.vue'

export default {
  components: {
    AppWizardLocation,
    AppWizardZone,
    AppWizardRegionArea
  },
  props: {
    query: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      land: {
        areas: Object.entries(LAND_AREAS),
        // The API does not support land regions
        regions: []
      },
      marine: {
        areas: Object.entries(MARINE_AREAS),
        regions: Object.entries(MARINE_REGIONS)
      },
      options: {
        zone: {
          lists: null,
          type: null,
          unit: {
            code: null,
            label: null,
            type: null,
            unit: null
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'wizard',
      'selectedWizardOptions'
    ]),
    ...mapGetters('criteria', [
      'queryParameters'
    ])
  },
  beforeMount () {
    if (this.query.reset) {
      this.gotoWizard('location')
    } else if (this.wizard) {
      this.gotoWizard(this.wizard)
    }
    const wizardOptions = this.selectedWizardOptions
    if (wizardOptions) {
      this.options[this.wizard] = wizardOptions
    }
  },
  methods: {
    ...mapMutations([
      'gotoWizard',
      'hideWizard',
      'wizardOptions'
    ]),
    criteriaChanged () {
      const routeQuery = this.$route.query
      const criteriaQuery = this.queryParameters

      return Object.entries(criteriaQuery).sort().toString() !== Object.entries(routeQuery).sort().toString()
    },
    goto (wizard, options) {
      if (wizard === 'zone') {
        this.options.zone.type = options.type
        this.options.zone.unit = {
          code: options.unitCode,
          label: options.unitLabel,
          type: options.unitType,
          unit: options.unitUnit
        }

        if (this.options.zone.unit.type === 'land') {
          this.options.zone.lists = ['county']
        } else {
          this.options.zone.lists = null
        }

        this.wizardOptions({
          wizard: 'zone',
          options: this.options.zone
        })
      }
      this.gotoWizard(wizard)
    },
    async search () {
      await this.$router.push({
        path: '/search',
        query: this.queryParameters
      })
    }
  }
}
</script>

<style scoped>
.container {
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  flex: 1;
}
</style>
