export class Alert {
  constructor ({
    id,
    affectedZones,
    areaDesc,
    certainty,
    description,
    ends,
    event,
    expiration,
    headline,
    instruction,
    issuance,
    references,
    sender,
    senderName,
    severity,
    status,
    urgency,
    url,
    vtec
  }) {
    this.__id = id
    this.__affectedZones = affectedZones
    this.__areaDesc = areaDesc
    this.__certainty = certainty
    this.__description = description
    this.__ends = ends
    this.__event = event
    this.__expiration = expiration
    this.__headline = headline
    this.__instruction = instruction
    this.__issuance = issuance
    this.__references = references
    this.__sender = sender
    this.__senderName = senderName
    this.__severity = severity
    this.__status = status
    this.__urgency = urgency
    this.__url = url
    this.__vtec = vtec
  }

  get id () {
    return this.__id
  }

  get affectedZones () {
    return this.__affectedZones
  }

  get areaDesc () {
    return this.__areaDesc
  }

  get certainty () {
    return this.__certainty
  }

  get description () {
    return this.__description
  }

  get ends () {
    return this.__ends
  }

  get event () {
    return this.__event
  }

  get expiration () {
    return this.__expiration
  }

  get headline () {
    return this.__headline
  }

  get instruction () {
    return this.__instruction
  }

  get issuance () {
    return this.__issuance
  }

  get references () {
    return this.__references
  }

  get severity () {
    return this.__severity
  }

  get sender () {
    return this.__sender
  }

  get senderName () {
    return this.__senderName
  }

  get status () {
    return this.__status
  }

  get urgency () {
    return this.__urgency
  }

  get url () {
    return this.__url
  }

  get vtec () {
    return this.__vtec
  }

  toString () {
    return this.__id
  }
}
