<template>
  <criteria-edit-control
    :display-value="displayValue"
    :input-valid="inputValid"
    :placeholder="placeholder"
    @cancel="cancel"
    @confirm="confirm"
  >
    <template #instructions>
      Enter latitude and longitude.  Use negatives for south or west hemispheres.
    </template>
    <template #edit>
      <div>
        <input
          v-model="latitudeValue"
          type="number"
          max="90"
          min="-90"
          placeholder="Latitude"
        >
      </div>
      <div>
        <input
          v-model="longitudeValue"
          type="number"
          max="180"
          min="-180"
          placeholder="Longitude"
        >
      </div>
    </template>
  </criteria-edit-control>
</template>

<script>
export default {
  props: {
    clearInputOnClose: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'Click to set coordinates'
    },
    value: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      latitudeValue: this.value ? this.value[1] : '',
      longitudeValue: this.value ? this.value[0] : ''
    }
  },
  computed: {
    displayValue () {
      if (this.value === null) {
        return ''
      }

      const latitudeMag = Math.abs(this.value[1])
      const latitudeDir = this.value[1] >= 0 ? 'N' : 'S'
      const longitudeMag = Math.abs(this.value[0])
      const longitudeDir = this.value[0] >= 0 ? 'E' : 'W'

      return `${latitudeMag} \u00B0${latitudeDir}, ${longitudeMag} \u00B0${longitudeDir}`
    },
    inputValid () {
      if (this.latitudeValue === '' || this.longitudeValue === '') {
        return false
      }

      return this.latitudeValue !== '' && this.longitudeValue !== '' &&
        this.latitudeValue >= -90 && this.latitudeValue <= 90 &&
        this.longitudeValue >= -180 && this.longitudeValue <= 180
    },
    isDrawerOpen () {
      return this.drawer ? this.drawer.isOpen() : true
    },
    isValueSet () {
      return this.value !== null
    }
  },
  inject: {
    drawer: 'drawer'
  },
  watch: {
    isDrawerOpen () {
      if (!this.isDrawerOpen && this.clearInputOnClose) {
        this.clear()
      }
    },
    value: {
      immediate: true,
      handler () {
        if (!this.isDrawerOpen && this.isValueSet) {
          this.drawer.open()
        }
      }
    }
  },
  methods: {
    clear () {
      this.$emit('input', null)
      this.latitudeValue = ''
      this.longitudeValue = ''
    },
    confirm () {
      if (this.latitudeValue === '' || this.longitudeValue === '') {
        this.$emit('input', null)
      }
      this.$emit('input', [this.longitudeValue, this.latitudeValue])
    },
    cancel () {
      this.latitudeValue = this.value ? this.value[1] : ''
      this.longitudeValue = this.value ? this.value[0] : ''
    }
  }
}
</script>

<style scoped>
input {
  height: 40px;
  background-color: #fff;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  position: relative;
  width: 100%;
  padding-left: 12px;
  padding-right: 44px;
  font-weight: 400;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 14px;
  z-index: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>
