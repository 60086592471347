<template>
  <div class="container">
    <div class="topbar">
      <div class="title">
        <slot name="title">
          Title
        </slot>
      </div>
      <div class="description">
        <slot name="description">
          Description
        </slot>
      </div>
    </div>
    <div class="path">
      <app-wizard-path />
    </div>
    <div class="options">
      <slot name="options">
        Options
      </slot>
    </div>
  </div>
</template>

<script>
import AppWizardPath from './WizardPath.vue'

export default {
  components: {
    AppWizardPath
  }
}
</script>

<style scoped>
.topbar {
  padding: 20px;
  background: #efefef;
}

.title {
  font-size: 120%;
  font-weight: bold;
}

.options {
  padding: 20px 30px;
}

.options ul {
  line-height: 30px;
}
</style>
