<template>
  <div class="container">
    CAP {{ formatLabel }} XML for this {{ owner }} is
    <a :href="apiUrlWithParams">provided by the API</a> located at:
    <pre class="url">{{ apiUrlWithParams }}</pre>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    // May be actual query or potential queries based on what is provided
    query: {
      type: [Object],
      default: () => {}
    },
    owner: {
      type: [String],
      default: 'search'
    }
  },
  computed: {
    ...mapGetters([
      'apiUrl'
    ]),
    formatLabel () {
      return (this.query.id) ? '' : 'ATOM'
    },
    apiUrlWithParams () {
      if (this.query.id) {
        return (new URL(`${this.apiUrl}/alerts/${this.query.id}.cap`)).href
      }

      let url
      if (this.query.history) {
        url = new URL(`${this.apiUrl}/alerts.atom`)
      } else {
        url = new URL(`${this.apiUrl}/alerts/active.atom`)
      }

      let parameters = this.query
      if (this.query.history) {
        const { history, ...query } = this.query
        parameters = query
      }

      for (const [name, value] of Object.entries(parameters)) {
        url.searchParams.set(name, value)
      }

      return url.href
    }
  }
}
</script>

<style scoped>
.container {
  line-height: 20px;
}

.url {
  overflow-x: auto;
  background: rgb(0, 0, 0, 0.05);
  padding: 10px;
  font-size: 90%;
  margin: 10px 0 0;
}
</style>
